import { createTheme } from "@mui/material/styles";

const griTheme = createTheme({
  palette: {
    primary: {
      main: "#FFFFFF",
    },
    secondary: {
      main: "#FFFFFF",
    },
    text: {
      primary: "#C8C8C8",
      secondary: "#FFFFFF",
    },
    background: {
      default: "#181818",
      paper: "#181818",
    },
  },
  typography: {
    fontFamily: ["Knockout"],
    h1: {
      fontWeight: "bold",
      fontSize: "15px",
      marginTop: "10px",
    },
    h2: {
      fontWeight: "400",
      fontSize: "15px",
      marginTop: "10px",
      fontFamily: ["Knockout"],
      appearance:["HTF34 Junior Sumo"],
      letterSpacing: "2px",
    },
    h3: {
      fontWeight: "400",
      fontSize: "22px",
      appearance:["HTF34 Junior Sumo"],
    },
    body1: {
      fontSize: "13px",
      margin: "10px 0 3px 0",
      fontFamily: ["Adobe Caslon Pro"],
      appearance:["Regular"],
    },
    body2: {
      fontSize: "13px",
      margin: "5px 0 3px 0",
      fontFamily: ["Knockout"],
      appearance:["HTF34 Junior Sumo"],
    },
    h6: {
      fontSize: "7px",
      fontFamily: ["Knockout"],
      appearance:["HTF30 Junior Welter Wt"],
      fontWeight: "500",
      margin: "7px 0 3px 0",
      letterSpacing: "0.8px",
      textTransform: "capitalize",
    },
    h5: {
      fontSize: "13px",
      fontWeightL: "500"
    },
  },
});

export default griTheme;