import { Typography } from "@material-ui/core";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import { makeStyles } from "@material-ui/core/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import React from "react";

// Components
import Advertising from "../../components/Advertising/Advertising";
import SliderMenu from "../../components/SliderMenu/SliderMenu";

// Assets
import divisor from "../../assets/Menu/divisor.svg";

// Style
import ProductCard from "../Product/ProductCard";
import "./Menu.css";
import ProductCardMobile from "../Product/ProductCardMobile";
import { useWindowSize } from "../../hooks/windowsSize";

const useStyles = makeStyles((theme) => ({
  accordionSummary: {
    "& .MuiAccordionSummary-expandIcon": {
      color: "#9e9e9e",
    },
  },
}));

function Menu(props) {
  const { isMobile } = useWindowSize();

  const classes = useStyles();

  const category = props.restaurant.menu.categories.find(
    (category) => category.name === props.category.name
  );

  const disableAdvertising = () => {
    props.setShow(false);
  };

  return (
    <div className="subcateg-menu">
      {props.show === true &&
        category?.advertisingActive &&
        category?.advertisingUrl && (
          <Advertising
            image={category?.advertisingUrl}
            disableAdvertising={disableAdvertising}
          />
        )}
      <React.Fragment>
        {category.subcategories &&
          category.active &&
          category.subcategories.length > 0 &&
          category.subcategories
            .slice()
            .sort((a, b) =>
              a.name?.toUpperCase() > b.name?.toUpperCase()
                ? 1
                : b.name?.toUpperCase() > a.name?.toUpperCase()
                ? -1
                : 0
            )
            .slice()
            .sort((a, b) =>
              a.order > b.order ? 1 : b.order > a.order ? -1 : 0
            )
            .filter(
              (subcategory) =>
                (subcategory.name === "Destacados" ||
                  subcategory.name === "Auspicios") &&
                subcategory.active &&
                subcategory.products?.length > 0
            )
            .map((subcategory, index) => (
              <div className="subcateg" key={subcategory.id}>
                <Accordion square elevation={0} key={index} expanded={false}>
                  <AccordionSummary>
                    <Typography
                      variant="h2"
                      color="primary"
                      className="subcateg-name"
                      style={
                        props.restaurant.name === "navarrocorreas"
                          ? { color: "#0A380D" }
                          : props.restaurant.name === "ufopoint"
                          ? { width: "100%", textAlign: "center" }
                          : null
                      }
                    >
                      <p
                        {...(props.restaurant.name === "griller"
                          ? { translate: "no" }
                          : props.restaurant.name === "urbanococinavu"
                          ? { translate: "no" }
                          : null)}
                      >
                        {props.subsidiaryLanguage === "es"
                          ? subcategory.name
                          : subcategory?.englishName
                          ? subcategory?.englishName
                          : subcategory.name}
                      </p>
                    </Typography>
                  </AccordionSummary>
                </Accordion>
                {subcategory?.products && subcategory.products.length > 0 && (
                  <div className="subcateg-featured">
                    {props.restaurant.name === "ufopoint" ? null : (
                      <div
                        className={
                          props.restaurant.name === "navarrocorreas"
                            ? "subcateg-NC"
                            : props.restaurant.name === "griller"
                            ? "subcateg-gri"
                            : "subcateg-divisor"
                        }
                      />
                    )}
                    <SliderMenu
                      subcategory={subcategory}
                      category={category}
                      restaurant={props.restaurant}
                      subsidiaryLanguage={props.subsidiaryLanguage}
                    />
                  </div>
                )}
              </div>
            ))}
      </React.Fragment>

      <React.Fragment>
        {category.subcategories && category.active ? (
          category.subcategories.length > 0 &&
          category.subcategories
            .slice()
            .sort((a, b) =>
              a.name?.toUpperCase() > b.name?.toUpperCase()
                ? 1
                : b.name?.toUpperCase() > a.name?.toUpperCase()
                ? -1
                : 0
            )
            .slice()
            .sort((a, b) =>
              a.order > b.order ? 1 : b.order > a.order ? -1 : 0
            )
            .filter(
              (subcategory) =>
                subcategory.name !== "Destacados" &&
                subcategory.name !== "Auspicios" &&
                subcategory.active &&
                subcategory.products?.length > 0
            )
            .map((subcategory, index, array) => (
              <div className="subcateg" key={subcategory.id}>
                <Accordion
                  key={index}
                  square
                  elevation={0}
                  defaultExpanded={() =>
                    props.restaurant.name === "navarrocorreas" &&
                    category.name === "Menu por Pasos"
                      ? index === 0
                        ? true
                        : false
                      : array.length === 1
                      ? true
                      : false
                  }
                >
                  <AccordionSummary
                    expandIcon={
                      props.restaurant.name === "ufopoint" ? null : (
                        <ExpandMoreIcon />
                      )
                    }
                    className={classes.accordionSummary}
                  >
                    <Typography
                      variant="h2"
                      color="primary"
                      className="subcateg-name"
                      {...(props.restaurant.name === "urbanococina"
                        ? { translate: "no" }
                        : props.restaurant.name === "urbanococinavu"
                        ? { translate: "no" }
                        : null)}
                      style={
                        props.restaurant.name === "navarrocorreas"
                          ? { color: "#0A380D" }
                          : props.restaurant.name === "ufopoint"
                          ? { width: "100%", textAlign: "center" }
                          : null
                      }
                    >
                      {props.subsidiaryLanguage === "es"
                        ? subcategory?.name
                        : subcategory?.englishName
                        ? subcategory?.englishName
                        : subcategory?.name}
                    </Typography>
                  </AccordionSummary>
                  {props.restaurant.name === "ufopoint" ? null : (
                    <div
                      className={
                        props.restaurant.name === "navarrocorreas"
                          ? "subcateg-NC"
                          : props.restaurant.name === "griller"
                          ? "subcateg-gri"
                          : "subcateg-divisor"
                      }
                    />
                  )}
                  <React.Fragment>
                    <ul className="subcateg-product-list">
                      {subcategory?.commentary ||
                      subcategory?.englishCommentary ? (
                        <div
                          style={{
                            margin: "12px 0px 20px 0px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flexDirection: "column",
                          }}
                        >
                          <div
                            style={{
                              marginBottom: "20px",
                              textAlign: "center",
                            }}
                            className="subcateg-product-list-item-data"
                          >
                            <Typography
                              variant="h2"
                              color="#FFFFFF"
                              className=""
                              {...(props.restaurant.name === "urbanococina"
                                ? { translate: "no" }
                                : props.restaurant.name === "urbanococinavu"
                                ? { translate: "no" }
                                : null)}
                              style={
                                props.restaurant.name === "ufopoint"
                                  ? {
                                      textTransform: "uppercase",
                                      fontWeight: "500",
                                      textAlign: "center",
                                    }
                                  : { width: "100%" }
                              }
                            >
                              {props.subsidiaryLanguage === "es"
                                ? subcategory?.commentary
                                : subcategory?.englishCommentary
                                ? subcategory?.englishCommentary
                                : subcategory?.commentary}
                            </Typography>
                          </div>
                          <img src={divisor} className="divisor" alt="" />
                        </div>
                      ) : null}
                      {subcategory.products &&
                        subcategory.products
                          .slice()
                          .sort((a, b) =>
                            a.order > b.order ? 1 : b.order > a.order ? -1 : 0
                          )
                          .map(
                            (product, index) =>
                              product.active && (
                                <AccordionDetails key={product.id}>
                                  {isMobile ? (
                                    <ProductCardMobile
                                      index={index}
                                      restaurant={props.restaurant}
                                      product={product}
                                      subcategory={subcategory}
                                      subsidiaryLanguage={
                                        props.subsidiaryLanguage
                                      }
                                      favourite={props.favourite}
                                      addFavouriteDish={props.addFavouriteDish}
                                      deleteFavouriteDish={
                                        props.deleteFavouriteDish
                                      }
                                    />
                                  ) : (
                                    <ProductCard
                                      index={index}
                                      restaurant={props.restaurant}
                                      product={product}
                                      subcategory={subcategory}
                                      subsidiaryLanguage={
                                        props.subsidiaryLanguage
                                      }
                                      favourite={props.favourite}
                                      addFavouriteDish={props.addFavouriteDish}
                                      deleteFavouriteDish={
                                        props.deleteFavouriteDish
                                      }
                                    />
                                  )}
                                </AccordionDetails>
                              )
                          )}
                    </ul>
                  </React.Fragment>
                </Accordion>
              </div>
            ))
        ) : (
          <React.Fragment>
            <h2 className="category-alert">
              {props.subsidiaryLanguage === "es" ? "Lo sentimos" : "Sorry"}
            </h2>
            <p className="category-alert">
              {props.subsidiaryLanguage === "es"
                ? "Categoría no disponible en este momento"
                : "Category not available at the moment"}
            </p>
          </React.Fragment>
        )}
      </React.Fragment>
    </div>
  );
}

export default Menu;
