import React from "react";

// Assets
import sign from "../../assets/Footer/sign.svg";

// Style
import "./Footer.css";

function Footer(props) {
  return (
    <footer className="footer">
      <div
        className={
          props.restaurant.name === "griller"
          ? "footer-content color-footer-gri"
          :
          props.restaurant.selectedTheme === "a" ||
          props.restaurant.selectedTheme === "b"
            ? "footer-content color-footer"
            : props.restaurant.selectedTheme === "c"
            ? "footer-content color-footer-2"
            : props.restaurant.selectedTheme === "d"
            ? "footer-content color-footer-3"
            : "footer-content color-footer"
        }
      >
        <img src={sign} className="footer-sign" alt="" />
      </div>
    </footer>
  );
}

export default Footer;
