import React, { useEffect, useRef } from "react";

// Components
import Background from "../../components/Background/Background";
import InitialSlide from "../../components/InitialSlide/InitialSlide";

// Style
import "./Landing.css";

import { TranslatorProvider } from "react-translate";
import en from "../../assets/i18n/en/translations.json";
import es from "../../assets/i18n/es/translations.json";
import { useAppContext } from "../../contexts/AppProvider";

function Landing(props) {
  const { subsidiaryLanguage } = useAppContext();
  const background = useRef(null);
  const initialSlide = useRef(null);
  const activateInitialSlide = () => {
    if (background?.current && initialSlide?.current) {
      background.current.style.display = "none";
      initialSlide.current.style.display = "block";
    }
  };

  const backgroundEffect = () => {
    if (background?.current) {
      background.current.className =
        "section landing background animate__animated animate__fadeOut";
    }
  };

  useEffect(() => {
    setTimeout(backgroundEffect, 2000);
    setTimeout(activateInitialSlide, 3000);
  });

  useEffect(() => {
    if (props.restaurant) {
      const body = document.getElementsByTagName("body")[0];
      props.restaurant.name === "ufopoint"
        ? (body.style.backgroundColor = "#181A1B")
        : (body.style.backgroundColor = "#FFFFFF");
    }
  }, [props.restaurant]);

  /**
    Agregar los clientes que no quieren mostrar Slide 👇
    const clients = [ 'cabanagrillus', 'cabanagrill', 'cabanagrillvolvo', 'cabañabar', 'griller', 'cabanabar','corte','harvey','kenkyo','lapancha','casabarro'];
  */

  if (props.restaurant.onboarding !== undefined) {
    if(!props.restaurant.onboarding){
      props.history.push(`/${props.restaurant.name}/menu`);
    }
  }

  const translations = subsidiaryLanguage === 'es' ? es : en;

  return (
    <TranslatorProvider translations={translations}>
      <section className="section landing">
        <div
          ref={background}
          className="section landing background"
          style={
            props.restaurant.name === "ufopoint"
              ? { backgroundColor: "#181A1B" }
              : { backgroundColor: "#ffffff" }
          }
        >
          <Background restaurant={props.restaurant} />
        </div>
        <div
          ref={initialSlide}
          className="section landing"
          style={
            props.restaurant.name === "ufopoint"
              ? { backgroundColor: "#181A1B", display: "none" }
              : { backgroundColor: "#ffffff", display: "none" }
          }
        >
          <InitialSlide restaurant={props.restaurant} id={props.id} />
        </div>
      </section>
      </TranslatorProvider>
  );
}

export default Landing;
