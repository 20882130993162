import React, { useState, useEffect } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { number } from "@storybook/addon-knobs";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Grid from "@material-ui/core/Grid";

// Assets
import crossIcon from "../../assets/Suggested/cross-icon.svg";
import sugIcon from "../../assets/Suggested/sug-icon-white.svg";
import sug from "../../assets/Topbar/sug.svg";
import sug2 from "../../assets/Topbar/sug-2.svg";
import sug3 from "../../assets/Topbar/sug-3.svg";
import sug4 from "../../assets/Topbar/sug-4.svg";

// Style
import "./SuggestedV2.css";

function SuggestedV2(props) {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    document.documentElement.style.overflowY = "hidden";
    setOpen(true);
  };

  const handleClose = () => {
    document.documentElement.style.overflowY = "auto";
    setOpen(false);
  };

  useEffect(() => {
    if (props.refresh > 0) {
      handleClickOpen();
    }
  }, [props.refresh]);

  return (
    <div>
      <Button
        className="suggestedv2-button"
        onClick={() => {
          handleClickOpen();
          props.suggestedHandler();
        }}
      >
        <img
          className="suggestedv2-sug-img"
          src={
            props.restaurant.selectedTheme === "a"
              ? sug
              : props.restaurant.selectedTheme === "b"
              ? sug2
              : props.restaurant.selectedTheme === "c"
              ? sug3
              : props.restaurant.selectedTheme === "d"
              ? sug4
              : sug
          }
          alt=""
        />
      </Button>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            backgroundColor: "transparent",
            boxShadow: "none",
          },
        }}
      >
        <Grid container className="suggestedv2-grid">
          <div className="suggestedv2">
            <div className="suggestedv2-top">
              <button
                className="suggestedv2-cross-button"
                onClick={handleClose}
              >
                <img src={crossIcon} className="suggestedv2-cross" alt="" />
              </button>
              <div className="suggestedv2-logo-wrapper">
                <img
                  src={props.restaurant.logo}
                  className="suggestedv2-logo"
                  alt=""
                />
              </div>
              <img src={sugIcon} className="suggestedv2-sug-icon" alt="" />
            </div>
            <Carousel
              {...{
                showArrows: true,
                showStatus: false,
                showIndicators: true,
                infiniteLoop: false,
                showThumbs: false,
                useKeyboardArrows: false,
                autoPlay: false,
                stopOnHover: true,
                swipeable: true,
                dynamicHeight: false,
                emulateTouch: false,
                transitionTime: number("transitionTime", 0, {}),
              }}
              className="suggestedv2-carousel"
            >
              {props.suggestedItems &&
                props.suggestedItems
                  .sort((a, b) =>
                    !a.suggestedImage && b.suggestedImage
                      ? 1
                      : a.suggestedImage && !b.suggestedImage
                      ? -1
                      : 0
                  )
                  .map(
                    (item, index) =>
                      item && (
                        <div className="suggestedv2-item" key={index}>
                          {item.suggestedImage ? (
                            <div className="suggestedv2-item-content">
                              <div className="suggestedv2-img-container">
                                <img
                                  className="suggestedv2-img"
                                  src={item.suggestedImage}
                                  alt=""
                                />
                                <div className="suggestedv2-img-back" />
                              </div>
                              <div className="suggestedv2-data">
                                <h2 className="suggestedv2-title">
                                  {item.name}
                                </h2>
                                <p className="suggestedv2-description">
                                  {item.description.length > 1 &&
                                    item.description}
                                </p>
                                {item.price > 0 && (
                                  <p className="suggestedv2-price">
                                    $
                                    <span
                                      style={
                                        item.discountPrice
                                          ? {
                                              textDecoration: "line-through",
                                              marginRight: "5px",
                                            }
                                          : { textDecoration: "none" }
                                      }
                                    >
                                      {item.price}
                                    </span>
                                    {item.discountPrice && (
                                      <React.Fragment>
                                        <br />
                                        <span
                                          style={{
                                            fontSize: "18px",
                                          }}
                                        >
                                          {" $" + item.discountPrice}
                                        </span>
                                      </React.Fragment>
                                    )}
                                  </p>
                                )}
                              </div>
                            </div>
                          ) : (
                            <div style={{ marginTop: "50px" }}>
                              <h2
                                className="suggestedv2-title"
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  textAlign: "center",
                                }}
                              >
                                {item.name}
                              </h2>
                              <p
                                className="suggestedv2-description"
                                style={{
                                  textAlign: "center",
                                  paddingTop: "20px",
                                  fontSize: "14px",
                                  letterSpacing: "1px",
                                  lineHeight: "23px",
                                  fontWeight: "400",
                                  margin: "10px 40px 10px 40px",
                                  height: "180px",
                                }}
                              >
                                {item.description.length > 1 &&
                                  item.description}
                              </p>
                              <p
                                className="suggestedv2-price"
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  paddingTop: "20px",
                                }}
                              >
                                ${item.price}
                              </p>
                              {item.price > 0 && (
                                <p
                                  className="suggestedv2-price"
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    paddingTop: "20px",
                                  }}
                                >
                                  $
                                  <span
                                    style={
                                      item.discountPrice
                                        ? {
                                            textDecoration: "line-through",
                                            marginRight: "5px",
                                          }
                                        : { textDecoration: "none" }
                                    }
                                  >
                                    {item.price}
                                  </span>
                                  {item.discountPrice && (
                                    <React.Fragment>
                                      <br />
                                      <span
                                        style={{
                                          fontSize: "18px",
                                        }}
                                      >
                                        {" $" + item.discountPrice}
                                      </span>
                                    </React.Fragment>
                                  )}
                                </p>
                              )}
                            </div>
                          )}
                        </div>
                      )
                  )}
            </Carousel>
          </div>
        </Grid>
      </Dialog>
    </div>
  );
}

export default SuggestedV2;
