import { ThemeProvider } from "@material-ui/core";
import CssBaseline from "@material-ui/core/CssBaseline";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import ReactGa from "react-ga";
import {
  alternativeTheme,
  darkTheme,
  defaultTheme,
  summerTheme,
} from "../../Themes/Theme";
import griTheme from "../../Themes/griTheme";
import ufoTheme from "../../Themes/ufoTheme";

// Components
import Footer from "../../components/Footer/Footer";
import Menu from "../../components/Menu/Menu";
import RestaurantInfo from "../../components/RestaurantInfo/RestaurantInfo";
import SuggestedAlert from "../../components/SuggestedAlert/SuggestedAlert";
import Topbar from "../../components/Topbar/Topbar";

import { TranslatorProvider } from "react-translate";
import en from "../../assets/i18n/en/translations.json";
import es from "../../assets/i18n/es/translations.json";

// Style
import "./Main.css";
import { useAppContext } from "../../contexts/AppProvider";

function Main(props) {
  const { subsidiaryLanguage, handleChangeSubsidiaryLanguage } = useAppContext();

  let favouriteLS = JSON.parse(
    localStorage.getItem("product " + props.restaurant.name)
  );
  if (!favouriteLS) {
    favouriteLS = [];
  }
  const [favourite, setFavourite] = useState(favouriteLS);

  let timeDeleteLS = JSON.parse(
    localStorage.getItem("time " + props.restaurant.name)
  );
  if (!timeDeleteLS) {
    timeDeleteLS = null;
  }
  const [timeDelete, setTimeDelete] = useState(timeDeleteLS);

  const [category, setCategory] = useState({ name: "home" });
  const suggestedAlert = useRef(null);
  const [suggestedItems, setSuggestedItems] = useState(null);
  const [show, setShow] = useState(true);

  const Themes = () => {
    if (
      props.restaurant.selectedTheme === "a" ||
      !props.restaurant.selectedTheme
    ) {
      if (props.restaurant.value === "1") {
        defaultTheme.typography.h1.fontFamily = "Montserrat";
        defaultTheme.typography.h2.fontFamily = "Montserrat";
        defaultTheme.typography.h3.fontFamily = "Montserrat";
        defaultTheme.typography.body1.fontFamily = "Montserrat";
        defaultTheme.typography.body2.fontFamily = "Montserrat";
        defaultTheme.typography.h5.fontFamily = "Montserrat";
        defaultTheme.typography.h6.fontFamily = "Montserrat";
      } else if (props.restaurant.value === "2") {
        defaultTheme.typography.h3.fontFamily = "Minion Pro";
        defaultTheme.typography.body1.fontFamily = "Minion Pro";
        defaultTheme.typography.h1.fontFamily = "Minion Pro";
        defaultTheme.typography.h2.fontFamily = "Minion Pro";
        defaultTheme.typography.body2.fontFamily = "Minion Pro";
        defaultTheme.typography.h5.fontFamily = "Minion Pro";
        defaultTheme.typography.h6.fontFamily = "Minion Pro";
      } else if (props.restaurant.value === "3") {
        defaultTheme.typography.h1.fontFamily = "Nunito";
        defaultTheme.typography.h3.fontFamily = "Nunito";
        defaultTheme.typography.body1.fontFamily = "Nunito";
        defaultTheme.typography.h6.fontFamily = "Nunito";
        defaultTheme.typography.h2.fontFamily = "Nunito";
        defaultTheme.typography.body2.fontFamily = "Nunito";
        defaultTheme.typography.h5.fontFamily = "Nunito";
      } else if (props.restaurant.value === "4") {
        defaultTheme.typography.h1.fontFamily = "Castoro";
        defaultTheme.typography.h2.fontFamily = "Castoro";
        defaultTheme.typography.body1.fontFamily = "Castoro";
        defaultTheme.typography.h3.fontFamily = "Castoro";
        defaultTheme.typography.h5.fontFamily = "Castoro";
        defaultTheme.typography.h6.fontFamily = "Castoro";
        defaultTheme.typography.body2.fontFamily = "Castoro";
      }
      return defaultTheme;
    } else if (props.restaurant.selectedTheme === "b") {
      if (props.restaurant.value === "1") {
        alternativeTheme.typography.h1.fontFamily = "Montserrat";
        alternativeTheme.typography.h2.fontFamily = "Montserrat";
        alternativeTheme.typography.h3.fontFamily = "Montserrat";
        alternativeTheme.typography.body1.fontFamily = "Montserrat";
        alternativeTheme.typography.body2.fontFamily = "Montserrat";
        alternativeTheme.typography.h5.fontFamily = "Montserrat";
        alternativeTheme.typography.h6.fontFamily = "Montserrat";
      } else if (props.restaurant.value === "2") {
        alternativeTheme.typography.h3.fontFamily = "Minion Pro";
        alternativeTheme.typography.body1.fontFamily = "Minion Pro";
        alternativeTheme.typography.h1.fontFamily = "Minion Pro";
        alternativeTheme.typography.h2.fontFamily = "Minion Pro";
        alternativeTheme.typography.body2.fontFamily = "Minion Pro";
        alternativeTheme.typography.h5.fontFamily = "Minion Pro";
        alternativeTheme.typography.h6.fontFamily = "Minion Pro";
      } else if (props.restaurant.value === "3") {
        alternativeTheme.typography.h1.fontFamily = "Nunito";
        alternativeTheme.typography.h3.fontFamily = "Nunito";
        alternativeTheme.typography.body1.fontFamily = "Nunito";
        alternativeTheme.typography.h6.fontFamily = "Nunito";
        alternativeTheme.typography.h2.fontFamily = "Nunito";
        alternativeTheme.typography.body2.fontFamily = "Nunito";
        alternativeTheme.typography.h5.fontFamily = "Nunito";
      } else if (props.restaurant.value === "4") {
        alternativeTheme.typography.h1.fontFamily = "Castoro";
        alternativeTheme.typography.h2.fontFamily = "Castoro";
        alternativeTheme.typography.body1.fontFamily = "Castoro";
        alternativeTheme.typography.h3.fontFamily = "Castoro";
        alternativeTheme.typography.h5.fontFamily = "Castoro";
        alternativeTheme.typography.h6.fontFamily = "Castoro";
        alternativeTheme.typography.body2.fontFamily = "Castoro";
      }
      return alternativeTheme;
    } else if (props.restaurant.selectedTheme === "c") {
      if (props.restaurant.value === "1") {
        summerTheme.typography.h1.fontFamily = "Montserrat";
        summerTheme.typography.h2.fontFamily = "Montserrat";
        summerTheme.typography.h3.fontFamily = "Montserrat";
        summerTheme.typography.body1.fontFamily = "Montserrat";
        summerTheme.typography.body2.fontFamily = "Montserrat";
        summerTheme.typography.h5.fontFamily = "Montserrat";
        summerTheme.typography.h6.fontFamily = "Montserrat";
      } else if (props.restaurant.value === "2") {
        summerTheme.typography.h3.fontFamily = "Minion Pro";
        summerTheme.typography.body1.fontFamily = "Minion Pro";
        summerTheme.typography.h1.fontFamily = "Minion Pro";
        summerTheme.typography.h2.fontFamily = "Minion Pro";
        summerTheme.typography.body2.fontFamily = "Minion Pro";
        summerTheme.typography.h5.fontFamily = "Minion Pro";
        summerTheme.typography.h6.fontFamily = "Minion Pro";
      } else if (props.restaurant.value === "3") {
        summerTheme.typography.h1.fontFamily = "Nunito";
        summerTheme.typography.h3.fontFamily = "Nunito";
        summerTheme.typography.body1.fontFamily = "Nunito";
        summerTheme.typography.h6.fontFamily = "Nunito";
        summerTheme.typography.h2.fontFamily = "Nunito";
        summerTheme.typography.body2.fontFamily = "Nunito";
        summerTheme.typography.h5.fontFamily = "Nunito";
      } else if (props.restaurant.value === "4") {
        summerTheme.typography.h1.fontFamily = "Castoro";
        summerTheme.typography.h2.fontFamily = "Castoro";
        summerTheme.typography.body1.fontFamily = "Castoro";
        summerTheme.typography.h3.fontFamily = "Castoro";
        summerTheme.typography.h5.fontFamily = "Castoro";
        summerTheme.typography.h6.fontFamily = "Castoro";
        summerTheme.typography.body2.fontFamily = "Castoro";
      }
      return summerTheme;
    } else if (props.restaurant.selectedTheme === "d") {
      if (props.restaurant.value === "1") {
        darkTheme.typography.h1.fontFamily = "Montserrat";
        darkTheme.typography.h2.fontFamily = "Montserrat";
        darkTheme.typography.h3.fontFamily = "Montserrat";
        darkTheme.typography.body1.fontFamily = "Montserrat";
        darkTheme.typography.body2.fontFamily = "Montserrat";
        darkTheme.typography.h5.fontFamily = "Montserrat";
        darkTheme.typography.h6.fontFamily = "Montserrat";
      } else if (props.restaurant.value === "2") {
        darkTheme.typography.h3.fontFamily = "Minion Pro";
        darkTheme.typography.body1.fontFamily = "Minion Pro";
        darkTheme.typography.h1.fontFamily = "Minion Pro";
        darkTheme.typography.h2.fontFamily = "Minion Pro";
        darkTheme.typography.body2.fontFamily = "Minion Pro";
        darkTheme.typography.h5.fontFamily = "Minion Pro";
        darkTheme.typography.h6.fontFamily = "Minion Pro";
      } else if (props.restaurant.value === "3") {
        darkTheme.typography.h1.fontFamily = "Nunito";
        darkTheme.typography.h3.fontFamily = "Nunito";
        darkTheme.typography.body1.fontFamily = "Nunito";
        darkTheme.typography.h6.fontFamily = "Nunito";
        darkTheme.typography.h2.fontFamily = "Nunito";
        darkTheme.typography.body2.fontFamily = "Nunito";
        darkTheme.typography.h5.fontFamily = "Nunito";
      } else if (props.restaurant.value === "4") {
        darkTheme.typography.h1.fontFamily = "Castoro";
        darkTheme.typography.h2.fontFamily = "Castoro";
        darkTheme.typography.body1.fontFamily = "Castoro";
        darkTheme.typography.h3.fontFamily = "Castoro";
        darkTheme.typography.h5.fontFamily = "Castoro";
        darkTheme.typography.h6.fontFamily = "Castoro";
        darkTheme.typography.body2.fontFamily = "Castoro";
      }
      return darkTheme;
    }
  };

  const disableSuggestedAlert = () => {
    document.body.style.overflow = "scroll";
    suggestedAlert.current.style.display = "none";
  };

  const categoryHandler = (category) => {
    window.scrollTo(0, 0);
    setCategory(category);
  };

  const addFavouriteDish = (productName) => {
    ReactGa.event({
      category: "favourite " + props.restaurant.name,
      action: `Agregado el producto ${subsidiaryLanguage === "es" ? productName.name : productName.englishName} `,
    });
    setFavourite([...favourite, productName]);
    console.log("favourite",favourite);
    setTimeDelete(new Date().getTime());
  };

  const deleteFavouriteDish = (productName) => {
    setFavourite(favourite.filter((item) => item.id !== productName.id));
    setTimeDelete(new Date().getTime());
  };

  const deleteAllFavouriteDishData = () => {
    localStorage.removeItem("product " + props.restaurant.name);
    localStorage.removeItem("time " + props.restaurant.name);
    setFavourite([]);
    setTimeDelete(null);
  };

  useEffect(() => {
    localStorage.setItem(
      "product " + props.restaurant.name,
      JSON.stringify(favourite)
    );
    localStorage.setItem(
      "time " + props.restaurant.name,
      JSON.stringify(timeDelete)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [favourite]);

  useEffect(() => {
    const itemsArray = [];
    props.restaurant.menu &&
      props.restaurant.menu.categories.forEach(
        (category) =>
          category.active &&
          category?.subcategories?.forEach(
            (subcategory) =>
              subcategory.active &&
              subcategory.products &&
              subcategory.products.forEach((product) => {
                if (product.active && product.suggested) {
                  itemsArray.push(product);
                }
              })
          )
      );
    setSuggestedItems(itemsArray);
    if (itemsArray.length === 0) {
      disableSuggestedAlert();
    }
  }, [props.restaurant]);

  useLayoutEffect(() => {
    if (timeDelete) {
      const now = new Date().getTime();
      if (favourite.length === 0) {
        deleteAllFavouriteDishData();
      } else if (parseInt(now, 10) - parseInt(timeDelete, 10) > 7200000) {
        deleteAllFavouriteDishData();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (props.restaurant) {
      const body = document.getElementsByTagName("body")[0];
      props.restaurant.name === "ufopoint" ||
      props.restaurant.selectedTheme === "d"
        ? (body.style.backgroundColor = "#181A1B")
        : props.restaurant.name === "griller"
        ? (body.style.backgroundColor = "#181818")
        : (body.style.backgroundColor = "#FFFFFF");
    }
  }, [props.restaurant]);

  const translations = subsidiaryLanguage === "es" ? es : en;

  return (
    <React.Fragment>
      <TranslatorProvider translations={translations}>
        <ThemeProvider
          theme={
            props.restaurant.name === "ufopoint"
              ? ufoTheme
              : props.restaurant.name === "griller"
              ? griTheme
              : Themes()
          }
        >
          <CssBaseline />
          <div
            ref={suggestedAlert}
            className="suggested-alert-component"
            style={
              suggestedItems && suggestedItems.length < 0
                ? { visibility: "hidden" }
                : { visibility: "visible" }
            }
          >
            {props.restaurant.showSuggestedAlert &&
              suggestedItems &&
              suggestedItems.length > 0 && (
                <SuggestedAlert
                  restaurant={props.restaurant}
                  disableSuggestedAlert={disableSuggestedAlert}
                />
              )}
          </div>
          <div className="content-wrapper">
            <Topbar
              suggestedItems={suggestedItems}
              restaurant={props.restaurant}
              category={category}
              setCategory={categoryHandler}
              id={props.id}
              setShow={setShow}
              favourite={favourite}
              deleteFavouriteDish={deleteFavouriteDish}
              language={props.language}
              setLanguage={props.setLanguage}
              subsidiaryLanguage={subsidiaryLanguage}
              handleChangeSubsidiaryLanguage={handleChangeSubsidiaryLanguage}
            />
            {category.name === "home" ? (
              <RestaurantInfo restaurant={props.restaurant} />
            ) : (
              <Menu
                category={category}
                restaurant={props.restaurant}
                show={show}
                setShow={setShow}
                favourite={favourite}
                timeDelete={timeDelete}
                addFavouriteDish={addFavouriteDish}
                deleteFavouriteDish={deleteFavouriteDish}
                language={props.language}
                subsidiaryLanguage={subsidiaryLanguage}
                handleChangeSubsidiaryLanguage={handleChangeSubsidiaryLanguage}
              />
            )}
            <div className="footer-wrapper">
              <Footer restaurant={props.restaurant} />
            </div>
          </div>
        </ThemeProvider>
      </TranslatorProvider>
    </React.Fragment>
  );
}

export default Main;
