import React, { useEffect } from "react";

// Assets
import close from "../../assets/Advertising/white-close-logo.svg";

// Style
import "./Advertising.css";

function Advertising(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="advertising-container">
      <div
        className="slide-close"
        onClick={() => {
          props.disableAdvertising();
        }}
      >
        <span className="advertising-close-text">Omitir</span>
        <img className="advertising-close-logo" src={close} alt="" />
      </div>
      <img className="advertising-img" src={props.image} alt="advertising" />
    </div>
  );
}

export default Advertising;
