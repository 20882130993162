import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";

// Components
import Auspice from "../../components/Auspice/Auspice";

// Assets
import sugIcon from "../../assets/SliderMenu/sug-icon.svg";

// Style
import "./SliderMenu.css";

function SliderMenu(props) {
  const renderCurrency = () => {
    const restaurants = ["cabañabar", "cabanagrill", "cabanagrillus"];

    if (props.restaurant.currency) {
      return props.restaurant.currency;
    }

    if (restaurants.includes(props.restaurant.name)) {
      return "€";
    }

    return "$";
  };

  //.filter((item) => item.active && item.image && item.logo)

  return (
    <React.Fragment>
      <Carousel
        {...{
          showStatus: false,
          showArrows: false,
          showThumbs: false,
          swipeable: true,
        }}
        className="slider-menu"
      >
        {props.subcategory &&
          props.subcategory.products
            .filter((item) => item.active)
            .slice()
            .sort((a, b) =>
              a.order > b.order ? 1 : b.order > a.order ? -1 : 0
            )
            .map((item, index) => (
              <div
                className="slider-menu-item"
                style={
                  props.restaurant.name === "ufopoint"
                    ? { backgroundColor: "transparent" }
                    : props.restaurant.name === "griller"
                    ? {
                        backgroundImage: `url(https://firebasestorage.googleapis.com/v0/b/mi-menu-staging.appspot.com/o/Imagenes%2FDestacados%2Flogo%2FMesa%20de%20trabajo%20101.png?alt=media&token=f6c3f098-e702-4012-b21f-bca6973ab449)`,
                      }
                    : { backgroundColor: "rgba(212, 206, 206, 0.24)" }
                }
                key={index}
              >
                {item.logo && (
                  <div className="subcateg-item-logo-wrapper">
                    <img
                      className="subcateg-item-logo"
                      src={item.logo}
                      alt=""
                    />
                  </div>
                )}
                {item.image && (
                  <div className={props.restaurant.name === "griller" ? "subcateg-item-img-wrapper-gri" : "subcateg-item-img-wrapper"}>
                    <img
                      className="subcateg-item-img"
                      src={item.image}
                      alt=""
                    />
                    {props.restaurant.name === "ufopoint" && (
                      <div className="subcateg-item-img-back" />
                    )}
                  </div>
                )}
                <div className="subcateg-item-content-wrapper">
                  <div className="subcateg-item-content">
                    <img src={sugIcon} className="subcateg-item-sug" alt="" />
                    <h2 className="subcateg-item-title">{props.subsidiaryLanguage === "es" ? item.name : item?.englishName ? item.englishName : item.name}</h2>
                    <h3 className="subcateg-item-name">
                      {props.subsidiaryLanguage === "es" ? item.description : item?.englishDescription ? item.englishDescription  : item.description}
                    </h3>
                    {item.price > 0 && (
                      <p className="subcateg-item-price">
                        {renderCurrency()}
                        <span
                          style={
                            item.discountPrice
                              ? {
                                  textDecoration: "line-through",
                                  marginRight: "5px",
                                }
                              : { textDecoration: "none" }
                          }
                        >
                          {item.price}
                        </span>
                        {item.discountPrice && (
                          <React.Fragment>
                            <span
                              style={{
                                fontSize: "18px",
                              }}
                            >
                              {" " + renderCurrency() + item.discountPrice}
                            </span>
                          </React.Fragment>
                        )}
                      </p>
                    )}
                  </div>
                </div>
              </div>
            ))}
      </Carousel>
      {props.category?.subcategories
        .filter((subcategory) => subcategory.name === "Auspicios")
        .slice()
        .sort((a, b) =>
          a.id?.toUpperCase() > b.id?.toUpperCase()
            ? 1
            : b.id?.toUpperCase() > a.id?.toUpperCase()
            ? -1
            : 0
        )
        .map((subcategory, index) => (
          <Auspice auspice={subcategory.items} key={index} />
        ))}
    </React.Fragment>
  );
}

export default SliderMenu;
