import React, { useEffect, useState } from "react";
import ReactGa from "react-ga";
import {
  Typography,
  IconButton,
  Dialog,
  DialogContent,
  Button,
  Menu,
  MenuItem,
} from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";

// Components
import Navbar from "../../components/Navbar/Navbar";
import Suggested from "../../components/Suggested/Suggested";
import SuggestedV2 from "../../components/SuggestedV2/SuggestedV2";

// Assets
import fav from "../../assets/Topbar/Favoritos.svg";
import fav2 from "../../assets/Topbar/Favoritos_2.svg";
import fav3 from "../../assets/Topbar/Favoritos_3.svg";
import fav4 from "../../assets/Topbar/Favoritos_4.svg";
import itemBorder from "../../assets/Topbar/item-border.svg";
import itemBorder2 from "../../assets/Topbar/item-border-2.svg";
import itemBorder3 from "../../assets/Topbar/item-border-3.svg";
import itemBorder4 from "../../assets/Topbar/item-border-4.svg";
import itemBorderSelected from "../../assets/Topbar/item-border-selected.svg";
import itemBorderSelected2 from "../../assets/Topbar/item-border-selected-2.svg";
import itemBorderSelected3 from "../../assets/Topbar/item-border-selected-3.svg";
import itemBorderSelected4 from "../../assets/Topbar/item-border-selected-4.svg";
import itemBorderSelectedNC from "../../assets/Topbar/item-border-selected-NC.svg";
import divisor from "../../assets/Menu/divisor.svg";

//icons
import LanguageIcon from "@material-ui/icons/Language";

// Style
import "./Topbar.css";
import { makeAbsoluteUrl } from "../../constants";

function Topbar(props) {
  const [openFav, setOpenFav] = React.useState(false);
  const [refresh, doRefresh] = useState(0);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickEnglish = () => {
    props.setLanguage("en");
    handleClose();
  };

  const handleClickSpanish = () => {
    props.setLanguage("es");
    handleClose();
  };

  const suggestedHandler = () => {
    ReactGa.event({
      category: "button",
      action: "Click en boton de Sugeridos",
    });
  };

  const favouriteHandler = () => {
    setOpenFav(true);
  };

  // let contador = 0;

  // const activos = props.restaurant.menu.categories.map(
  //   (element) => element.active && contador++
  // );

  // useEffect(() => {
  //   let contador = 0;

  //   const activos = props.restaurant.menu.categories.map(
  //     (element) => element.active && contador++
  //   );
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  useEffect(() => {
    if (props.favourite.length < 1) {
      setOpenFav(false);
    }
  }, [props.favourite]);

  return (
    <div className="topbar-wrapper">
      <nav
        className={
          props.restaurant.name === "griller"
            ? "topbar color-gri"
            : props.restaurant.selectedTheme === "a" ||
              props.restaurant.selectedTheme === "b"
            ? "topbar color-1"
            : props.restaurant.selectedTheme === "c"
            ? "topbar color-2"
            : props.restaurant.selectedTheme === "d"
            ? "topbar color-3"
            : "topbar color-1"
        }
      >
        <Navbar
          suggestedItems={props.suggestedItems}
          restaurant={props.restaurant}
          setCategory={props.setCategory}
          id={props.id}
          doRefresh={doRefresh}
          subsidiaryLanguage={props.subsidiaryLanguage}
          handleChangeSubsidiaryLanguage={props.handleChangeSubsidiaryLanguage}
        />
        <div className="topbar-logo-wrapper">
          {(props.restaurant.logo && props.restaurant.urlLogoImage) ||
          (props.restaurant.englishLogo &&
            props.restaurant.urlEnglishLogoImage) ? (
            <a
              target="_blank"
              href={
                props.subsidiaryLanguage === "es"
                  ? makeAbsoluteUrl(props.restaurant.urlLogoImage)
                  : makeAbsoluteUrl(props.restaurant?.urlEnglishLogoImage)
              }
              rel="noopener noreferrer"
              className="topbar-logo"
            >
              {props.subsidiaryLanguage === "es" && props.restaurant?.logo ? (
                <img
                  src={props.restaurant.logo}
                  className="topbar-logo"
                  alt=""
                  style={
                    props.restaurant.name === "griller"
                      ? null
                      : (props.restaurant.selectedTheme === "c" ||
                          props.restaurant.selectedTheme === "d") &&
                        props.restaurant.name !== "ufopoint"
                      ? { filter: "invert(1)" }
                      : null
                  }
                />
              ) : null}
              {props.subsidiaryLanguage === "en" &&
              props.restaurant?.englishLogo ? (
                <img
                  src={props.restaurant.englishLogo}
                  className="topbar-logo"
                  alt=""
                  style={
                    props.restaurant.name === "griller"
                      ? null
                      : (props.restaurant.selectedTheme === "c" ||
                          props.restaurant.selectedTheme === "d") &&
                        props.restaurant.name !== "ufopoint"
                      ? { filter: "invert(1)" }
                      : null
                  }
                />
              ) : null}
            </a>
          ) : (
            <div className="topbar-logo-wrapper">
              {props.subsidiaryLanguage === "es" && props.restaurant?.logo ? (
                <img
                  src={props.restaurant.logo}
                  className="topbar-logo"
                  alt=""
                  style={
                    props.restaurant.name === "griller"
                      ? null
                      : (props.restaurant.selectedTheme === "c" ||
                          props.restaurant.selectedTheme === "d") &&
                        props.restaurant.name !== "ufopoint"
                      ? { filter: "invert(1)" }
                      : null
                  }
                />
              ) : null}
              {props.subsidiaryLanguage === "en" &&
              props.restaurant?.englishLogo ? (
                <img
                  src={props.restaurant.englishLogo}
                  className="topbar-logo"
                  alt=""
                  style={
                    props.restaurant.name === "griller"
                      ? null
                      : (props.restaurant.selectedTheme === "c" ||
                          props.restaurant.selectedTheme === "d") &&
                        props.restaurant.name !== "ufopoint"
                      ? { filter: "invert(1)" }
                      : null
                  }
                />
              ) : null}
            </div>
          )}
        </div>
        {props.suggestedItems &&
          props.suggestedItems.length > 0 &&
          (props.restaurant.name !== "ufopoint" ? (
            <Suggested
              refresh={refresh}
              suggestedHandler={suggestedHandler}
              suggestedItems={props.suggestedItems}
              restaurant={props.restaurant}
              subsidiaryLanguage={props.subsidiaryLanguage}
            />
          ) : (
            <SuggestedV2
              refresh={refresh}
              suggestedHandler={suggestedHandler}
              suggestedItems={props.suggestedItems}
              restaurant={props.restaurant}
            />
          ))}
        {props.favourite && props.favourite.length > 0 && (
          <Button
            className="topbar-fav-button "
            onClick={favouriteHandler}
            style={
              props.suggestedItems && props.suggestedItems.length > 0
                ? { right: "50px" }
                : { right: "10px" }
            }
          >
            <img
              className="topbar-fav"
              src={
                props.restaurant.name === "griller"
                  ? fav3
                  : props.restaurant.selectedTheme === "a"
                  ? fav
                  : props.restaurant.selectedTheme === "b"
                  ? fav2
                  : props.restaurant.selectedTheme === "c"
                  ? fav3
                  : props.restaurant.selectedTheme === "d"
                  ? fav4
                  : fav4
              }
              alt=""
            />
          </Button>
        )}
        <div className="topbar-navigation">
          {props.restaurant && props.restaurant.menu
            ? props.restaurant.menu.categories
                .slice()
                .sort((a, b) =>
                  a?.name?.toUpperCase() > b?.name?.toUpperCase()
                    ? 1
                    : b?.name?.toUpperCase() > a?.name?.toUpperCase()
                    ? -1
                    : 0
                )
                .slice()
                .sort((a, b) =>
                  a.order > b.order ? 1 : b.order > a.order ? -1 : 0
                )
                .map(
                  (item, index) =>
                    item.active &&
                    item?.subcategories?.length > 0 && (
                      <div
                        key={index}
                        onClick={() => {
                          props.setCategory(item);
                          ReactGa.event({
                            category: "button",
                            action: `Click en Boton de ${item.name}`,
                          });
                          if (props.setShow) props.setShow(true);
                        }}
                        className="topbar-navigation-item"
                      >
                        <div className="top-navigation-img">
                          <div
                            className={
                              props.category.name === item.name
                                ? "topbar-icon-container icon-active"
                                : "topbar-icon-container"
                            }
                          >
                            <img
                              src={item.image}
                              className={
                                props.restaurant.name === "griller"
                                  ? "topbar-img filter-c"
                                  : props.restaurant.selectedTheme === "a"
                                  ? "topbar-img"
                                  : props.restaurant.selectedTheme === "b"
                                  ? "topbar-img filter-b"
                                  : props.restaurant.selectedTheme === "c" ||
                                    props.restaurant.selectedTheme === "d"
                                  ? "topbar-img filter-c"
                                  : "topbar-img"
                              }
                              alt="topbar icon"
                            />
                          </div>
                          {props.restaurant.name !== "ufopoint" ? (
                            props.category.name === item.name ? (
                              <img
                                src={
                                  props.restaurant.name === "navarrocorreas"
                                    ? itemBorderSelectedNC
                                    : props.restaurant.name === "griller"
                                    ? itemBorderSelected2
                                    : props.restaurant.selectedTheme === "a"
                                    ? itemBorderSelected
                                    : props.restaurant.selectedTheme === "b"
                                    ? itemBorderSelected2
                                    : props.restaurant.selectedTheme === "c"
                                    ? itemBorderSelected3
                                    : props.restaurant.selectedTheme === "d"
                                    ? itemBorderSelected4
                                    : itemBorderSelected
                                }
                                className="topbar-item-border-selected"
                                alt=""
                              />
                            ) : (
                              <img
                                src={
                                  props.restaurant.name === "griller"
                                    ? itemBorder3
                                    : props.restaurant.selectedTheme === "a"
                                    ? itemBorder
                                    : props.restaurant.selectedTheme === "b"
                                    ? itemBorder2
                                    : props.restaurant.selectedTheme === "c"
                                    ? itemBorder3
                                    : props.restaurant.selectedTheme === "d"
                                    ? itemBorder4
                                    : itemBorder
                                }
                                className="topbar-item-border"
                                alt=""
                                style={
                                  props.restaurant.name === "navarrocorreas"
                                    ? {
                                        filter:
                                          "hue-rotate(-70deg) saturate(100%) ",
                                      }
                                    : null
                                }
                              />
                            )
                          ) : props.category.name === item.name ? (
                            <div className="topbar-item-border-selected" />
                          ) : (
                            <div className="topbar-item-border" />
                          )}
                        </div>
                        {props.restaurant.name === "ufopoint" ? (
                          props.category.name === item.name ? (
                            <div>
                              <Typography
                                color="textSecondary"
                                className="topbar-item-name"
                              >
                                {props.subsidiaryLanguage === "es"
                                  ? item?.name
                                  : item?.englishName
                                  ? item.englishName
                                  : item.name}
                              </Typography>
                              <div className="item-name-divisor" />
                            </div>
                          ) : (
                            <Typography
                              variant="h6"
                              color="textSecondary"
                              className="topbar-item-name item-name-opacity"
                            >
                              <p
                                {...(props.restaurant.name === "griller"
                                  ? { translate: "no" }
                                  : props.restaurant.name === "urbanococinavu"
                                  ? { translate: "no" }
                                  : null)}
                              >
                                {props.subsidiaryLanguage === "es"
                                  ? item?.name
                                  : item?.englishName
                                  ? item.englishName
                                  : item.name}
                              </p>
                            </Typography>
                          )
                        ) : props.restaurant.name === "cabañabar" ||
                          props.restaurant.name === "cabañagrill" ? (
                          item.id === "1U4L4iv8isKTFOPlGwHc" ? (
                            <Typography
                              variant="h6"
                              color="textSecondary"
                              className="topbar-item-name"
                            >
                              {item.name2}
                            </Typography>
                          ) : (
                            <Typography
                              variant="h6"
                              color="textSecondary"
                              className="topbar-item-name"
                            >
                              {props.subsidiaryLanguage === "es"
                                ? item?.name
                                : item?.englishName
                                ? item.englishName
                                : item.name}
                            </Typography>
                          )
                        ) : props.restaurant.name === "griller" ? (
                          props.category.name === item.name ? (
                            <Typography
                              variant="h6"
                              color="#DF1F25"
                              className="topbar-item-name-gri"
                            >
                              {props.subsidiaryLanguage === "es"
                                ? item?.name?.toUpperCase()
                                : item?.englishName
                                ? item.englishName?.toUpperCase()
                                : item.name?.toUpperCase()}
                            </Typography>
                          ) : (
                            <Typography
                              variant="h6"
                              color="textSecondary"
                              className="topbar-item-name-gri"
                            >
                              {props.subsidiaryLanguage === "es"
                                ? item?.name?.toUpperCase()
                                : item?.englishName
                                ? item.englishName?.toUpperCase()
                                : item.name?.toUpperCase()}
                            </Typography>
                          )
                        ) : (
                          <Typography
                            color="textSecondary"
                            {...(props.restaurant.name === "urbanococina"
                              ? { translate: "no" }
                              : props.restaurant.name === "urbanococinavu"
                              ? { translate: "no" }
                              : null)}
                            className="topbar-item-name"
                            style={
                              props.restaurant.name === "navarrocorreas"
                                ? { color: "#0A350D" }
                                : { fontSize: "12px" }
                            }
                          >
                            {props.subsidiaryLanguage === "es"
                              ? item?.name
                              : item?.englishName
                              ? item.englishName
                              : item.name}
                          </Typography>
                        )}
                      </div>
                    )
                )
            : ""}
        </div>
      </nav>
      {props.favourite.length > 0 && (
        <Dialog
          open={openFav}
          onClose={() => {
            setOpenFav(false);
          }}
          className="favourite-container"
        >
          <form className="favourite-form">
            <div className="favourite-form-icons">
              <IconButton disableRipple onClick={() => setOpenFav(false)}>
                <img src={fav} className="favourite-fav-icon" alt="" />
              </IconButton>
              <IconButton disableRipple onClick={() => setOpenFav(false)}>
                <ClearIcon style={{ color: "#002e3c" }} />
              </IconButton>
            </div>
            <DialogContent>
              <ul style={{ listStyle: "none", padding: "0" }}>
                {props.favourite.map((producto, index) => (
                  <li>
                    <div>
                      <h3>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          {props.subsidiaryLanguage === "es"
                            ? producto.name
                            : producto?.englishName
                            ? producto?.englishName
                            : producto.name}
                          <IconButton
                            onClick={() => props.deleteFavouriteDish(producto)}
                          >
                            <DeleteForeverIcon style={{ color: "#002e3c" }} />
                          </IconButton>
                        </div>
                      </h3>
                      {index !== props.favourite.length - 1 && (
                        <img
                          src={divisor}
                          className="divisor-favourite"
                          alt=""
                        />
                      )}
                    </div>
                  </li>
                ))}
              </ul>
            </DialogContent>
          </form>
        </Dialog>
      )}
      <Menu
        id="account-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={() => handleClose(false)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        style={{ top: "40px" }}
      >
        <MenuItem onClick={handleClickSpanish}>Spanish</MenuItem>
        <MenuItem onClick={handleClickEnglish}>English</MenuItem>
      </Menu>
    </div>
  );
}

export default Topbar;
