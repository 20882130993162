import React from "react";
import { useHistory } from "react-router-dom";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { boolean, number } from "@storybook/addon-knobs";

// Assets
import close from "../../assets/InitialSlide/close.svg";
import backgroundSlidePhoto1 from "../../assets/InitialSlide/background1.jpg";
import sliderElegi from "../../assets/InitialSlide/slider-elegi.svg";
import backgroundSlidePhoto2 from "../../assets/InitialSlide/background2.jpg";
import sliderSugeridos from "../../assets/InitialSlide/slider-sugeridos.svg";
import star from "../../assets/InitialSlide/star.svg";

// Style
import "./InitialSlide.css";

import { useTranslate } from "react-translate";

function InitialSlide(props) {
  let t = useTranslate("SLIDE");
  const history = useHistory();
  const tooglesGroupId = "Toggles";
  const valuesGroupId = "Values";
  const getInitialSlideConfigurableProps = () => ({
    showArrows: boolean("showArrows", false, tooglesGroupId),
    showStatus: boolean("showStatus", false, tooglesGroupId),
    showIndicators: boolean("showIndicators", true, tooglesGroupId),
    infiniteLoop: boolean("infiniteLoop", false, tooglesGroupId),
    showThumbs: boolean("showThumbs", false, tooglesGroupId),
    useKeyboardArrows: boolean("useKeyboardArrows", true, tooglesGroupId),
    autoPlay: boolean("autoPlay", false, tooglesGroupId),
    stopOnHover: boolean("stopOnHover", true, tooglesGroupId),
    swipeable: boolean("swipeable", true, tooglesGroupId),
    dynamicHeight: boolean("dynamicHeight", false, tooglesGroupId),
    emulateTouch: boolean("emulateTouch", true, tooglesGroupId),
    thumbWidth: number("thumbWidth", 100, {}, valuesGroupId),
    selectedItem: number("selectedItem", 0, {}, valuesGroupId),
    interval: number("interval", 6000, {}, valuesGroupId),
    transitionTime: number("transitionTime", 150, {}, valuesGroupId),
    swipeScrollTolerance: number("swipeScrollTolerance", 5, {}, valuesGroupId),
  });
  return (
    <React.Fragment>
      <img src={backgroundSlidePhoto1} style={{ display: "none" }} alt="" />
      <img src={sliderElegi} style={{ display: "none" }} alt="" />
      <img src={backgroundSlidePhoto2} style={{ display: "none" }} alt="" />
      <img src={sliderSugeridos} style={{ display: "none" }} alt="" />
      <div
        className={
          props.restaurant.name === "ufopoint"
            ? "slide-close-ufo"
            : "slide-close"
        }
        onClick={() => {
          history.push(props.id + "/menu");
        }}
      >
        <span className="slide-close-text">{t("SKIP")}</span>
        <img className="slide-close-logo" src={close} alt="" />
      </div>
      <Carousel
        {...getInitialSlideConfigurableProps()}
        className={
          props.restaurant.name === "ufopoint"
            ? "initial-slide initial-slide-ufo"
            : "initial-slide"
        }
      >
        <div className="slide-item">
          <div className="slide-item-content-initial">
            <img
              className="slide-big-logo"
              src={props.restaurant.logo}
              alt=""
            />
            <div className="slide-space" />
            <div>
              <p className="slide-title">{t("WELCOME")}</p>
            </div>
          </div>
        </div>
        <div className="slide-item">
          <div className="slide-item-content">
            <img
              className="slide-small-logo"
              src={props.restaurant.logo}
              alt=""
            ></img>
            {props.restaurant.name !== "ufopoint" && (
              <div
                className="slide-img-content"
                style={{
                  backgroundColor: "transparent",
                  backgroundImage: `url(${backgroundSlidePhoto1})`,
                  backgroundPosition: "bottom",
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                }}
              >
                <img className="slider-img" src={sliderElegi} alt=""></img>
              </div>
            )}
            <div>
              <img src={star} className="slide-star-icon" alt="" />
              {props.restaurant.name === "cabañabar" ||
              props.restaurant.name === "cabanagrill" ? (
                <p className="slide-title">{t("CHOOSE")}</p>
              ) : (
                <p className="slide-title">{t("I_CHOSE")}</p>
              )}
              <p className="slide-description">{t("FIND")}</p>
            </div>
          </div>
        </div>
        <div className="slide-item">
          <div className="slide-item-content">
            <img
              className="slide-small-logo"
              src={props.restaurant.logo}
              alt=""
            ></img>
            {props.restaurant.name !== "ufopoint" && (
              <div
                className="slide-img-content"
                style={{
                  backgroundColor: "transparent",
                  backgroundImage: `url(${backgroundSlidePhoto2})`,
                  backgroundPosition: "bottom",
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                }}
              >
                <img className="slider-img" src={sliderSugeridos} alt=""></img>
              </div>
            )}
            <div>
              <img src={star} className="slide-star-icon" alt="" />
              {props.restaurant.name === "cabañabar" ||
              props.restaurant.name === "cabanagrill" ? (
                <p className="slide-title">{t("SUGGESTIONS")}</p>
              ) : (
                <p className="slide-title">{t("SUGGESTED")}</p>
              )}
              <p className="slide-description">{t("INFO")}</p>
            </div>
          </div>
        </div>
      </Carousel>
    </React.Fragment>
  );
}

export default InitialSlide;
