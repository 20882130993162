import React, { useState } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { number } from "@storybook/addon-knobs";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import Grid from "@material-ui/core/Grid";
import { IconButton } from "@material-ui/core";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import ArrowLeftIcon from "@material-ui/icons/ArrowLeft";
// Assets
import crossIcon from "../../assets/Suggested/black-cross-icon.svg";
import crossWhiteIcon from "../../assets/Suggested/cross-icon.svg";
import sugIcon from "../../assets/Suggested/sug-icon.svg";
import sugIconWhite from "../../assets/Suggested/sug-icon-white.svg";
import sug from "../../assets/Topbar/sug.svg";
import sug2 from "../../assets/Topbar/sug-2.svg";
import sug3 from "../../assets/Topbar/sug-3.svg";
import sug4 from "../../assets/Topbar/sug-4.svg";

// Style
import "./Suggested.css";

function Suggested(props) {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    document.documentElement.style.overflowY = "hidden";
    setOpen(true);
  };

  const handleClose = () => {
    document.documentElement.style.overflowY = "auto";
    setOpen(false);
  };

  const renderCurrency = () => {
    const restaurants = ["cabañabar", "cabanagrill", "cabanagrillus"];

    if (props.restaurant.currency) {
      return props.restaurant.currency;
    }

    if (restaurants.includes(props.restaurant.name)) {
      return "€";
    }

    return "$";
  };

  const returnDescription = (description) => {
    const newDesc = description.match(/.{1,97}/g)[0];
    const newDescription = newDesc + "...";
    return newDescription;
  };

  React.useEffect(() => {
    if (props.refresh > 0) {
      handleClickOpen();
    }
  }, [props.refresh]);

  //201d1d

  return (
    <div>
      <Button
        className="suggested-button"
        onClick={() => {
          handleClickOpen();
          props.suggestedHandler();
        }}
      >
        <img
          className="suggested-sug-img"
          src={
            props.restaurant.name === "griller"
              ? sug3
              : props.restaurant.selectedTheme === "a"
              ? sug
              : props.restaurant.selectedTheme === "b"
              ? sug2
              : props.restaurant.selectedTheme === "c"
              ? sug3
              : props.restaurant.selectedTheme === "d"
              ? sug4
              : sug
          }
          alt=""
        />
      </Button>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            backgroundColor: "transparent",
            boxShadow: "none",
            paddingTop: "20px",
            marginBottom: "20px",
          },
        }}
      >
        <Grid container className="suggested-grid">
          <div
            className={
              props.restaurant.name !== "griller"
                ? "suggested"
                : "suggested-gri"
            }
          >
            <div className="suggested-top">
              <button className="suggested-cross-button" onClick={handleClose}>
                <img
                  src={
                    props.restaurant.name === "griller"
                      ? crossWhiteIcon
                      : crossIcon
                  }
                  className="suggested-cross"
                  alt=""
                />
              </button>
              {props.restaurant.logo && (
                <div className="suggested-logo-wrapper">
                  <img
                    src={props.restaurant.logo}
                    className="suggested-logo"
                    alt=""
                  />
                </div>
              )}

              <img
                src={
                  props.restaurant.name === "griller" ? sugIconWhite : sugIcon
                }
                className="suggested-sug-icon"
                alt=""
              />
            </div>
            <Carousel
              {...{
                showArrows: true,
                showStatus: false,
                showIndicators: true,
                infiniteLoop: true,
                showThumbs: false,
                useKeyboardArrows: false,
                autoPlay: false,
                stopOnHover: true,
                swipeable: true,
                dynamicHeight: false,
                emulateTouch: false,
                transitionTime: number("transitionTime", 0, {}),
              }}
              {...(props.restaurant.name === "griller" && {
                renderArrowPrev: (clickHandler, hasPrev) =>
                  hasPrev && (
                    <IconButton
                      className="carousel-prev-button"
                      onClick={clickHandler}
                    >
                      <ArrowLeftIcon color="primary" />
                    </IconButton>
                  ),
              })}
              {...(props.restaurant.name === "griller" && {
                renderArrowNext: (clickHandler, hasNext) =>
                  hasNext && (
                    <IconButton
                      className="carousel-next-button"
                      onClick={clickHandler}
                    >
                      <ArrowRightIcon color="primary" />
                    </IconButton>
                  ),
              })}
              className="suggested-carousel"
            >
              {props.suggestedItems &&
                props.suggestedItems
                  .sort((a, b) =>
                    !a.suggestedImage && b.suggestedImage
                      ? 1
                      : a.suggestedImage && !b.suggestedImage
                      ? -1
                      : 0
                  )
                  .map(
                    (item, index) =>
                      item && (
                        <div
                          className="suggested-item"
                          key={index}
                          style={
                            props.restaurant.name === "griller" &&
                            item.suggestedImage
                              ? {
                                  backgroundSize: "320px 450px",
                                  backgroundRepeat: "no-repeat",
                                  backgroundPosition: "center center",
                                  border: "transparent",
                                  backgroundImage: `url(${item.suggestedImage}`,
                                }
                              : null
                          }
                        >
                          {item.suggestedImage ? (
                            props.restaurant.name !== "griller" && (
                              <div
                                className={
                                  props.restaurant.name !== "griller"
                                    ? "suggested-item-content"
                                    : "suggested-item-content-gri"
                                }
                              >
                                <div className="suggested-img-container">
                                  <img
                                    className="suggested-img"
                                    src={item.suggestedImage}
                                    alt=""
                                  />
                                </div>
                                <h2
                                  className={
                                    props.restaurant.name !== "griller"
                                      ? "suggested-title"
                                      : "suggested-title-gri"
                                  }
                                >
                                  {props.subsidiaryLanguage === "es"
                                    ? item?.name?.length > 100
                                      ? returnDescription(item?.name)
                                      : item?.name
                                    : item?.englishName
                                    ? item?.englishName?.length > 100
                                      ? returnDescription(item?.englishName)
                                      : item?.englishName
                                    : item?.name?.length > 100
                                    ? returnDescription(item?.name)
                                    : item?.name}
                                </h2>
                                {(item?.price > 0 ||
                                  item?.englishPrice > 0) && (
                                  <p
                                    className={
                                      props.restaurant.name !== "griller"
                                        ? "suggested-price"
                                        : "suggested-price-gri"
                                    }
                                  >
                                    {renderCurrency()}
                                    <span
                                      style={
                                        item.discountPrice
                                          ? {
                                              textDecoration: "line-through",
                                              marginRight: "5px",
                                            }
                                          : { textDecoration: "none" }
                                      }
                                    >
                                      {props.subsisubsidiaryLanguage === "es"
                                        ? item?.price
                                        : item?.englishPrice
                                        ? item?.englishPrice
                                        : item?.price}
                                    </span>
                                    {item.discountPrice && (
                                      <React.Fragment>
                                        <br />
                                        <span
                                          style={{
                                            fontSize: "18px",
                                          }}
                                        >
                                          {" " +
                                            renderCurrency() +
                                            item.discountPrice}
                                        </span>
                                      </React.Fragment>
                                    )}
                                  </p>
                                )}
                                <p className="suggested-description">
                                  {(item?.description?.length > 1 ||
                                    item.englishDescription?.length > 1) &&
                                  props.subsidiaryLanguage === "es"
                                    ? item?.description
                                    : item?.englishDescription
                                    ? item?.englishDescription
                                    : item?.description}
                                </p>
                              </div>
                            )
                          ) : (
                            <div
                              className={
                                props.restaurant.name === "griller"
                                  ? "suggested-div-gri"
                                  : ""
                              }
                            >
                              <h2
                                className={
                                  props.restaurant.name !== "griller"
                                    ? "suggested-title"
                                    : "suggested-title-gri"
                                }
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  textAlign: "center",
                                }}
                              >
                                {props.subsidiaryLanguage === "es"
                                  ? item?.name?.length > 100
                                    ? returnDescription(item?.name)
                                    : item?.name
                                  : item?.englishName
                                  ? item?.englishName?.length > 100
                                    ? returnDescription(item?.englishName)
                                    : item?.englishName
                                  : item?.name?.length > 100
                                  ? returnDescription(item?.name)
                                  : item?.name}
                              </h2>
                              <p
                                className="suggested-description"
                                style={{
                                  textAlign: "center",
                                  paddingTop: "20px",
                                  fontSize: "14px",
                                  letterSpacing: "1px",
                                  lineHeight: "23px",
                                  fontWeight: "400",
                                  margin: "10px 40px 10px 40px",
                                }}
                              >
                                {(item?.description?.length > 1 ||
                                  item.englishDescription?.length > 1) &&
                                props.subsidiaryLanguage === "es"
                                  ? item?.description
                                  : item?.englishDescription
                                  ? item?.englishDescription
                                  : item?.description}
                              </p>
                              {(item.price > 0 || item?.englishPrice > 0) && (
                                <p
                                  className={
                                    props.restaurant.name !== "griller"
                                      ? "suggested-price"
                                      : "suggested-price-gri"
                                  }
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    paddingTop: "20px",
                                  }}
                                >
                                  {renderCurrency()}
                                  <span
                                    style={
                                      item.discountPrice
                                        ? {
                                            textDecoration: "line-through",
                                            marginRight: "5px",
                                          }
                                        : { textDecoration: "none" }
                                    }
                                  >
                                    {props.subsisubsidiaryLanguage === "es"
                                      ? item?.price
                                      : item?.englishPrice
                                      ? item?.englishPrice
                                      : item?.price}
                                  </span>
                                  {item.discountPrice && (
                                    <React.Fragment>
                                      <span
                                        style={{
                                          fontSize: "18px",
                                        }}
                                      >
                                        {" " +
                                          renderCurrency() +
                                          item.discountPrice}
                                      </span>
                                    </React.Fragment>
                                  )}
                                </p>
                              )}
                            </div>
                          )}
                        </div>
                      )
                  )}
            </Carousel>
          </div>
        </Grid>
      </Dialog>
    </div>
  );
}

export default Suggested;
