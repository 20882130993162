import React, { createContext, useContext, useEffect, useState } from "react";
import { useLocalStorage } from "../hooks/localStorage/useLocalStorage";

const appContext = createContext();

export function AppProvider({ children }) {
  const subsidiaryLanguage = useProvideSubsidiaryLanguage();
  return (
    <appContext.Provider value={subsidiaryLanguage}>
      {children}
    </appContext.Provider>
  );
}

export const useAppContext = () => {
  return useContext(appContext);
};

function useProvideSubsidiaryLanguage() {
  const [isFirstRun, setIsFirstRun] = useState(true);

  const initialValue = "es";

  const [localStorageLanguage, setLocalStorageLanguage] = useLocalStorage(
    "subsidiaryLanguage",
    initialValue
  );

  const [subsidiaryLanguage, setSubsidiaryLanguage] = useState(initialValue);

  const handleChangeSubsidiaryLanguage = (newValue) => {
    setSubsidiaryLanguage(newValue);
  };

  useEffect(() => {
    if (!subsidiaryLanguage) {
      return;
    }
    setLocalStorageLanguage(subsidiaryLanguage);
  }, [subsidiaryLanguage]);

  useEffect(() => {
    if (isFirstRun) {
      setIsFirstRun(false);
      setSubsidiaryLanguage(localStorageLanguage);
    }
  }, [localStorageLanguage]);

  return {
    subsidiaryLanguage,
    handleChangeSubsidiaryLanguage,
  };
}
