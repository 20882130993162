import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import { createStore, combineReducers } from "redux";
import {
  ReactReduxFirebaseProvider,
  firebaseReducer,
} from "react-redux-firebase";
import { createFirestoreInstance, firestoreReducer } from "redux-firestore";
import firebaseConfigProd from "./firebase/prod.json";
import firebaseConfigStg from "./firebase/staging.json";

// App
import App from "./App";
import { AppProvider } from "./contexts/AppProvider";

// Firebase
const rrfConfig = {
  userProfile: "restaurants",
  useFirestoreForProfile: true,
};
if (
  process.env.REACT_APP_DEPLOY_ENVIRONMENT === "staging" ||
  process.env.DEPLOY_ENVIRONMENT === "staging"
) {
  firebase.initializeApp(firebaseConfigStg);
} else {
  firebase.initializeApp(firebaseConfigProd);
}

firebase.firestore();

const rootReducer = combineReducers({
  firebase: firebaseReducer,
  firestore: firestoreReducer,
});

const initialState = {};

const store = createStore(rootReducer, initialState);

const rrfProps = {
  firebase,
  config: rrfConfig,
  dispatch: store.dispatch,
  createFirestoreInstance,
};

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <Provider store={store}>
        <ReactReduxFirebaseProvider {...rrfProps}>
          <AppProvider>
            <Switch>
              <App />
            </Switch>
          </AppProvider>
        </ReactReduxFirebaseProvider>
      </Provider>
    </Router>
  </React.StrictMode>,
  document.getElementById("root")
);
