import React from "react";

// Assets
import logo from "../../assets/Background/logo.svg";
import mimenu from "../../assets/Background/mimenu.svg";
import latech from "../../assets/Background/latech.svg";
import logoWhite from "../../assets/Background/logo-white.svg";
import mimenuWhite from "../../assets/Background/mimenu-white.svg";
import latechWhite from "../../assets/Background/latech-white.svg";

// Style
import "./Background.css";

function Background(props) {
  return (
    <div className="header-background">
      <div className="background-logo-wrapper">
        <img
          src={props.restaurant.name === "ufopoint" ? logoWhite : logo}
          className="background-logo animate__animated animate__fadeIn"
          alt=""
        />
        <img
          src={props.restaurant.name === "ufopoint" ? mimenuWhite : mimenu}
          className="background-mimenu animate__animated animate__fadeIn"
          alt=""
        />
      </div>
      <img
        src={props.restaurant.name === "ufopoint" ? latechWhite : latech}
        className="background-latech animate__animated animate__fadeIn"
        alt=""
      />
    </div>
  );
}

export default Background;
