import { useEffect, useState } from "react";

export const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
    isMobile: false,
    isMedium: false,
    isLarge: false,
    isXLarge: false,
    isXXLarge: false,
  });
  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
        isMobile: window.innerWidth < 640,
        isMedium: window.innerWidth >= 640 && window.innerWidth < 768,
        isLarge: window.innerWidth >= 768 && window.innerWidth < 1024,
        isXLarge: window.innerWidth >= 1024 && window.innerWidth < 1280,
        isXXLarge: window.innerWidth >= 1280,
        isDesktop:  window.innerWidth >= 1024
      });
    }
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return windowSize;
};
