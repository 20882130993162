import { Grid, Typography } from "@material-ui/core";
import React from "react";

import glutenIcon from "../../assets/Conditions/gluten-free-icon.png";
import spicyIcon from "../../assets/Conditions/spicy-food-icon.png";
import veganIcon from "../../assets/Conditions/vegan-food-icon.png";
import sugIcon from "../../assets/Suggested/sug-icon.svg";
// Assets
import divisor from "../../assets/Menu/divisor.svg";

import FavouriteButton from "./FavouriteButton";

const ProductCardMobile = ({
  index,
  restaurant,
  product,
  subcategory,
  subsidiaryLanguage,
  favourite,
  addFavouriteDish,
  deleteFavouriteDish,
}) => {
  const renderCurrency = () => {
    const restaurants = ["cabañabar", "cabanagrill", "cabanagrillus"];

    if (restaurant.currency) {
      return restaurant.currency;
    }

    if (restaurants.includes(restaurant.name)) {
      return "€";
    }

    return "$";
  };

  const isInFavourites = (product) => {
    let flag = favourite.filter((item) => {
      if (product?.englishName) {
        return (
          item.name === product.name ||
          item?.englishName === product?.englishName
        );
      }
      return item.name === product.name;
    });
    console.log("flag", flag);

    return flag?.length > 0;
  };

  return (
    <Grid container direction="row">
      <Grid className="subcateg-product-list-item" item xs={8}>
        <div className="">
          {product.suggested && (
            <img src={sugIcon} className="subcateg-product-list-sug" alt="" />
          )}
          <Typography
            variant="h3"
            color="secondary"
            className="subcateg-product-list-title"
            {...(restaurant.name === "urbanococina"
              ? { translate: "no" }
              : restaurant.name === "urbanococinavu"
              ? { translate: "no" }
              : null)}
            style={
              restaurant.name === "ufopoint"
                ? {
                    textTransform: "uppercase",
                    fontWeight: "600",
                  }
                : null
            }
          >
            {subsidiaryLanguage !== "es"
              ? product.englishName
                ? product.englishName
                : product.name
              : product.name}
          </Typography>
        </div>
        <div>
          {product.spicy && (
            <img
              src={spicyIcon}
              className={
                restaurant.name === "griller"
                  ? "subcateg-product-list-cond filter-wh"
                  : restaurant.selectedTheme === "a" ||
                    restaurant.selectedTheme === "b"
                  ? "subcateg-product-list-cond"
                  : restaurant.selectedTheme === "c" ||
                    restaurant.selectedTheme === "d"
                  ? "subcateg-product-list-cond filter-wh"
                  : "subcateg-product-list-cond"
              }
              alt=""
            />
          )}
          {product.vegan && (
            <img
              src={veganIcon}
              className={
                restaurant.name === "griller"
                  ? "subcateg-product-list-cond filter-wh"
                  : restaurant.selectedTheme === "a" ||
                    restaurant.selectedTheme === "b"
                  ? "subcateg-product-list-cond"
                  : restaurant.selectedTheme === "c" ||
                    restaurant.selectedTheme === "d"
                  ? "subcateg-product-list-cond filter-wh"
                  : "subcateg-product-list-cond"
              }
              alt=""
            />
          )}
          {product.glutenFree && (
            <img
              src={glutenIcon}
              className={
                restaurant.name === "griller"
                  ? "subcateg-product-list-cond filter-wh"
                  : restaurant.selectedTheme === "a" ||
                    restaurant.selectedTheme === "b"
                  ? "subcateg-product-list-cond"
                  : restaurant.selectedTheme === "c" ||
                    restaurant.selectedTheme === "d"
                  ? "subcateg-product-list-cond filter-wh"
                  : "subcateg-product-list-cond"
              }
              alt=""
            />
          )}
        </div>
        {subsidiaryLanguage === "es" ? (
          product?.description && (
            <Typography
              variant="body1"
              color="textPrimary"
              className="subcateg-product-list-desc"
              {...(restaurant.name === "urbanococina"
                ? { translate: "no" }
                : restaurant.name === "urbanococinavu"
                ? { translate: "no" }
                : null)}
            >
              {product?.description}
            </Typography>
          )
        ) : (
          <Typography
            variant="body1"
            color="textPrimary"
            className="subcateg-product-list-desc"
            {...(restaurant.name === "urbanococina"
              ? { translate: "no" }
              : restaurant.name === "urbanococinavu"
              ? { translate: "no" }
              : null)}
          >
            {product?.englishDescription
              ? product?.englishDescription
              : product?.description}
          </Typography>
        )}
        {(product.price !== 0 || product?.englishprice !== 0) && (
          <Typography
            variant="body2"
            color="textPrimary"
            className="subcateg-product-list-price"
          >
            <span
              style={
                product.discountPrice
                  ? {
                      textDecoration: "line-through",
                    }
                  : { textDecoration: "none" }
              }
            >
              {renderCurrency()}
              {subsidiaryLanguage === "es"
                ? product.price
                : product.englishPrice
                ? product.englishPrice
                : product.price}
            </span>
            {product.discountPrice && (
              <React.Fragment>
                <br />
                <span
                  style={{
                    fontSize: "18px",
                  }}
                >
                  {" " + renderCurrency() + product.discountPrice}
                </span>
              </React.Fragment>
            )}
          </Typography>
        )}
      </Grid>
      <Grid item xs={4} style={{ borderRadius: "8px", position: "relative" }}>
        {product?.imageUrl && (
          <img
            src={product?.imageUrl}
            alt="product image"
            style={{
              width: "120px",
              height: "112px",
              objectFit: "cover",
              borderRadius: "8px",
            }}
          />
        )}
        <FavouriteButton
          restaurant={restaurant}
          product={product}
          favourite={favourite}
          isInFavourites={isInFavourites}
          addFavouriteDish={addFavouriteDish}
          deleteFavouriteDish={deleteFavouriteDish}
        />
      </Grid>
      <div
        item
        xs={12}
        justify="center"
        alignItems="center"
        style={{
          width: "100%",
          padding: "15px 0px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {restaurant.name === "ufopoint"
          ? index !== subcategory.products.length - 1 && (
              <div className="divisor-ufo" />
            )
          : index !== subcategory.products.length - 1 && (
              <img src={divisor} className="divisor" alt="" />
            )}
      </div>
    </Grid>
  );
};

export default ProductCardMobile;
