import { useState } from "react";

export const useLocalStorage = (key, initialValue) => {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const item = window?.localStorage.getItem(key)
        ? JSON?.parse(window?.localStorage.getItem(key))
        : false;
      if (item) {
          return item;
      }
      return initialValue;
    } catch (err) {
      console.error(
        "Ocurrio un error al intentar obtener los datos de Local Storage"
      );
      return initialValue;
    }
  });

  const setValue = (value) => {
    setStoredValue(() => {
      // eslint-disable-next-line no-unused-expressions
      window?.localStorage.setItem(key, JSON.stringify(value));
      return value;
    });
  };

  return [storedValue, setValue];
};
