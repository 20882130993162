import React from "react";
import { NavLink } from "react-router-dom";
import { slide as Menu } from "react-burger-menu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInstagramSquare,
  faFacebookSquare,
  faWhatsappSquare,
} from "@fortawesome/free-brands-svg-icons";

// Assets
import hamburgerIcon from "../../assets/Navbar/hamburger-icon.svg";
import hamburgerIcon2 from "../../assets/Navbar/hamburger-icon-2.svg";
import hamburgerIcon3 from "../../assets/Navbar/hamburger-icon-3.svg";
import hamburgerIcon4 from "../../assets/Navbar/hamburger-icon-4.svg";
import hamburgerIconUfo from "../../assets/Navbar/hamburger-icon-ufo.svg";
import hamburgerIconGri from "../../assets/Navbar/hamburger-icon-gri.svg";
import crossIcon from "../../assets/Navbar/cross.svg";
import crossIcon2 from "../../assets/Navbar/cross-2.svg";
import crossIcon3 from "../../assets/Navbar/cross-3.svg";
import crossIcon4 from "../../assets/Navbar/cross-4.svg";
import crossIconUfo from "../../assets/Navbar/cross-ufo.svg";
import crossIconGri from "../../assets/Navbar/cross-gri.svg";
import thx from "../../assets/Navbar/thx.svg";
import sign from "../../assets/Navbar/sign.svg";
import EsFlag from "../../assets/Navbar/ESP.svg";
import UkFlag from "../../assets/Navbar/ENG.svg";

import mimenuLogoUfo from "../../assets/Navbar/logo.svg";

// Style
import "./Navbar.css";
import { Typography } from "@material-ui/core";

class Navbar extends React.Component {
  constructor(props) {
    super(props);
    this.categories = this.props.restaurant.menu.categories;
    this.state = {
      menuOpen: false,
    };
  }

  handleStateChange(state) {
    this.setState({ menuOpen: state.isOpen });
  }

  closeMenu() {
    this.setState({ menuOpen: false });
  }

  toggleMenu() {
    this.setState((state) => ({ menuOpen: !state.menuOpen }));
  }

  render() {
    return (
      <nav className="navbar-menu">
        <Menu
          customBurgerIcon={
            <img
              src={
                this.props.restaurant.name === "ufopoint"
                  ? hamburgerIconUfo
                  : this.props.restaurant.name === "griller"
                  ? hamburgerIconGri
                  : this.props.restaurant.selectedTheme === "a"
                  ? hamburgerIcon
                  : this.props.restaurant.selectedTheme === "b"
                  ? hamburgerIcon2
                  : this.props.restaurant.selectedTheme === "c"
                  ? hamburgerIcon3
                  : this.props.restaurant.selectedTheme === "d"
                  ? hamburgerIcon4
                  : hamburgerIcon
              }
              alt=""
            />
          }
          customCrossIcon={
            <img
              src={
                this.props.restaurant.name === "ufopoint"
                  ? crossIconUfo
                  : this.props.restaurant.name === "griller"
                  ? crossIconGri
                  : this.props.restaurant.selectedTheme === "a"
                  ? crossIcon
                  : this.props.restaurant.selectedTheme === "b"
                  ? crossIcon2
                  : this.props.restaurant.selectedTheme === "c"
                  ? crossIcon3
                  : this.props.restaurant.selectedTheme === "d"
                  ? crossIcon4
                  : crossIcon
              }
              alt=""
            />
          }
          className={
            this.props.restaurant.name === "ufopoint"
              ? "menu-ufo bm-menu-background-ufo"
              : this.props.restaurant.name === "griller"
              ? "menu bm-menu-background-gri"
              : this.props.restaurant.selectedTheme === "a" ||
                this.props.restaurant.selectedTheme === "b"
              ? "menu bm-menu-background-1"
              : this.props.restaurant.selectedTheme === "c"
              ? "menu bm-menu-background-2"
              : this.props.restaurant.selectedTheme === "d"
              ? "menu bm-menu-background-3"
              : "menu bm-menu-background-1"
          }
          scaleDown
          isOpen={this.state.menuOpen}
          onStateChange={(state) => this.handleStateChange(state)}
          onMouseDown={() => this.closeMenu()}
        >
          <div>
            {this.props.restaurant.name === "ufopoint" && (
              <img className="navbar-mimenu-logo" src={mimenuLogoUfo} alt="" />
            )}
            {/*this.props.restaurant.name === "griller" && (
              <img className="navbar-mimenu-logo" src={mimenuLogoGri} alt="" />
            )*/}
            <NavLink
              className={
                this.props.restaurant.name === "ufopoint"
                  ? "menu-item color-item-ufo"
                  : this.props.restaurant.name === "navarrocorreas"
                  ? "menu-item color-item-NC"
                  : this.props.restaurant.name === "griller"
                  ? "menu-item color-item-gri"
                  : this.props.restaurant.selectedTheme === "a" ||
                    !this.props.restaurant.selectedTheme
                  ? "menu-item color-item"
                  : this.props.restaurant.selectedTheme === "b"
                  ? "menu-item color-item-2"
                  : this.props.restaurant.selectedTheme === "c"
                  ? "menu-item color-item-3"
                  : this.props.restaurant.selectedTheme === "d"
                  ? "menu-item color-item-4"
                  : "menu-item color-item"
              }
              to="#"
              onClick={() => {
                this.props.setCategory({ name: "home" });
                this.closeMenu();
              }}
            >
              <Typography
                variant="h1"
                className={
                  this.props.restaurant.name === "ufopoint"
                    ? "color-item-ufo"
                    : this.props.restaurant.name === "navarrocorreas"
                    ? "color-item-NC"
                    : this.props.restaurant.name === "griller"
                    ? "color-item-gri"
                    : this.props.restaurant.selectedTheme === "a" ||
                      !this.props.restaurant.selectedTheme
                    ? "color-item"
                    : this.props.restaurant.selectedTheme === "b"
                    ? "color-item-2"
                    : this.props.restaurant.selectedTheme === "c"
                    ? "color-item-3"
                    : this.props.restaurant.selectedTheme === "d"
                    ? "color-item-4"
                    : "color-item"
                }
              >
                HOME
              </Typography>
            </NavLink>
            {this.props.suggestedItems &&
              this.props.suggestedItems.length > 0 && (
                <NavLink
                  className={
                    this.props.restaurant.name === "ufopoint"
                      ? "menu-item color-item-ufo"
                      : this.props.restaurant.name === "navarrocorreas"
                      ? "menu-item color-item-NC"
                      : this.props.restaurant.name === "griller"
                      ? "menu-item color-item-gri"
                      : this.props.restaurant.selectedTheme === "a" ||
                        !this.props.restaurant.selectedTheme
                      ? "menu-item color-item"
                      : this.props.restaurant.selectedTheme === "b"
                      ? "menu-item color-item-2"
                      : this.props.restaurant.selectedTheme === "c"
                      ? "menu-item color-item-3"
                      : this.props.restaurant.selectedTheme === "d"
                      ? "menu-item color-item-4"
                      : "menu-item color-item"
                  }
                  to="#"
                  onClick={() => {
                    this.props.doRefresh((prev) => prev + 1);
                    this.props.setCategory({ name: "home" });
                    this.closeMenu();
                  }}
                >
                  <Typography
                    variant="h1"
                    className={
                      this.props.restaurant.name === "ufopoint"
                        ? "color-item-ufo"
                        : this.props.restaurant.name === "navarrocorreas"
                        ? "color-item-NC"
                        : this.props.restaurant.name === "griller"
                        ? "color-item-gri"
                        : this.props.restaurant.selectedTheme === "a" ||
                          !this.props.restaurant.selectedTheme
                        ? "color-item"
                        : this.props.restaurant.selectedTheme === "b"
                        ? "color-item-2"
                        : this.props.restaurant.selectedTheme === "c"
                        ? "color-item-3"
                        : this.props.restaurant.selectedTheme === "d"
                        ? "color-item-4"
                        : "color-item"
                    }
                  >
                    {this.props.restaurant.name === "cabañabar" ||
                    this.props.restaurant.name === "cabanagrill"
                      ? "SUGERENCIAS"
                      : this.props.subsidiaryLanguage === "es"
                      ? "SUGERIDOS"
                      : "SUGGESTIONS"}
                  </Typography>
                </NavLink>
              )}
            {this.props.restaurant?.reservationLink && (
              <a
                className={
                  this.props.restaurant.name === "ufopoint"
                    ? "menu-item color-item-ufo"
                    : this.props.restaurant.name === "navarrocorreas"
                    ? "menu-item color-item-NC"
                    : this.props.restaurant.name === "griller"
                    ? "menu-item color-item-gri"
                    : this.props.restaurant.selectedTheme === "a" ||
                      !this.props.restaurant.selectedTheme
                    ? "menu-item color-item"
                    : this.props.restaurant.selectedTheme === "b"
                    ? "menu-item color-item-2"
                    : this.props.restaurant.selectedTheme === "c"
                    ? "menu-item color-item-3"
                    : this.props.restaurant.selectedTheme === "d"
                    ? "menu-item color-item-4"
                    : "menu-item color-item"
                }
                target="_blank"
                rel="noopener noreferrer"
                href={this.props.restaurant?.reservationLink ?? ""}
              >
                <Typography
                  variant="h1"
                  className={
                    this.props.restaurant.name === "ufopoint"
                      ? "color-item-ufo"
                      : this.props.restaurant.name === "navarrocorreas"
                      ? "color-item-NC"
                      : this.props.restaurant.name === "griller"
                      ? "color-item-gri"
                      : this.props.restaurant.selectedTheme === "a" ||
                        !this.props.restaurant.selectedTheme
                      ? "color-item"
                      : this.props.restaurant.selectedTheme === "b"
                      ? "color-item-2"
                      : this.props.restaurant.selectedTheme === "c"
                      ? "color-item-3"
                      : this.props.restaurant.selectedTheme === "d"
                      ? "color-item-4"
                      : "color-item"
                  }
                >
                  {this.props.subsidiaryLanguage === "en"
                    ? "RESERVATION"
                    : "RESERVA"}
                </Typography>
              </a>
            )}
          </div>
          <div className="navbar-footer">
            {this.props.restaurant.name === "navarrocorreas" ||
            this.props.restaurant.name === "ufopoint" ||
            this.props.restaurant.name === "griller"
              ? this.props.restaurant && this.props.restaurant.menu
                ? this.props.restaurant.menu.categories
                    .slice()
                    .sort((a, b) =>
                      a.order > b.order ? 1 : b.order > a.order ? -1 : 0
                    )
                    .map(
                      (item, index) =>
                        item?.subcategories?.length > 0 && (
                          <NavLink
                            key={index}
                            onClick={() => {
                              this.props.setCategory(item);
                              this.closeMenu();
                            }}
                            className={
                              this.props.restaurant.name === "navarrocorreas"
                                ? "menu-item color-item-NC"
                                : this.props.restaurant.name === "ufopoint"
                                ? "menu-item color-item-ufo"
                                : this.props.restaurant.name === "griller"
                                ? "menu-item color-item-gri"
                                : ""
                            }
                            to="#"
                          >
                            <Typography
                              variant="h1"
                              className={
                                this.props.restaurant.name === "ufopoint"
                                  ? "color-item-ufo"
                                  : this.props.restaurant.name ===
                                    "navarrocorreas"
                                  ? "color-item-NC"
                                  : this.props.restaurant.name === "griller"
                                  ? "color-item-gri"
                                  : ""
                              }
                            >
                              {this.props.subsidiaryLanguage === "en"
                                ? item?.englishName?.toUpperCase()
                                : item?.name?.toUpperCase()}
                            </Typography>
                          </NavLink>
                        )
                    )
                : ""
              : this.props.subsidiaryLanguage === "es" && (
                  <img src={thx} className="navbar-thx" alt="" />
                )}
          </div>
          <div className="navbar-footer" style={{ width: "100%" }}>
            <div className="navbar-description">
              <Typography
                variant="h5"
                className={
                  this.props.restaurant.name === "griller"
                    ? "navbar-text color-text-gri"
                    : this.props.restaurant.selectedTheme === "a"
                    ? "navbar-text color-text"
                    : this.props.restaurant.selectedTheme === "b"
                    ? "navbar-text color-text-2"
                    : this.props.restaurant.selectedTheme === "c"
                    ? "navbar-text color-text-3"
                    : this.props.restaurant.selectedTheme === "d"
                    ? "navbar-text color-text-4"
                    : "navbar-text color-text"
                }
              >
                {this.props.restaurant.email}
              </Typography>
              <Typography
                variant="h5"
                className={
                  this.props.restaurant.name === "griller"
                    ? "navbar-text color-text-gri"
                    : this.props.restaurant.selectedTheme === "a"
                    ? "navbar-text color-text"
                    : this.props.restaurant.selectedTheme === "b"
                    ? "navbar-text color-text-2"
                    : this.props.restaurant.selectedTheme === "c"
                    ? "navbar-text color-text-3"
                    : this.props.restaurant.selectedTheme === "d"
                    ? "navbar-text color-text-4"
                    : "navbar-text color-text"
                }
              >
                {this.props.restaurant.phone}
              </Typography>
            </div>
            <div
              style={
                !this.props.restaurant.englishSupport ||
                this.props.restaurant.englishSupport === false
                  ? { padding: "40px 0px 120px 0px" }
                  : {}
              }
            >
              {this.props.restaurant.instagram && (
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={this.props.restaurant.instagram}
                >
                  <FontAwesomeIcon
                    className={
                      this.props.restaurant.name === "navarrocorreas"
                        ? "navbar-social-icon color-icon-NC"
                        : this.props.restaurant.name === "griller"
                        ? "navbar-social-icon color-icon-gri"
                        : this.props.restaurant.selectedTheme === "a"
                        ? "navbar-social-icon color-icon"
                        : this.props.restaurant.selectedTheme === "b"
                        ? "navbar-social-icon color-icon-2"
                        : this.props.restaurant.selectedTheme === "c"
                        ? "navbar-social-icon color-icon-3"
                        : this.props.restaurant.selectedTheme === "d"
                        ? "navbar-social-icon color-icon-4"
                        : "navbar-social-icon color-icon"
                    }
                    icon={faInstagramSquare}
                  />
                </a>
              )}
              {true && (
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={this.props.restaurant.facebook}
                >
                  <FontAwesomeIcon
                    className={
                      this.props.restaurant.name === "navarrocorreas"
                        ? "navbar-social-icon color-icon-NC"
                        : this.props.restaurant.name === "griller"
                        ? "navbar-social-icon color-icon-gri"
                        : this.props.restaurant.selectedTheme === "a"
                        ? "navbar-social-icon color-icon"
                        : this.props.restaurant.selectedTheme === "b"
                        ? "navbar-social-icon color-icon-2"
                        : this.props.restaurant.selectedTheme === "c"
                        ? "navbar-social-icon color-icon-3"
                        : this.props.restaurant.selectedTheme === "d"
                        ? "navbar-social-icon color-icon-4"
                        : "navbar-social-icon color-icon"
                    }
                    icon={faFacebookSquare}
                  />
                </a>
              )}
              {true && (
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`https://wa.me/${this.props.restaurant.whatsapp}`}
                >
                  <FontAwesomeIcon
                    className={
                      this.props.restaurant.name === "navarrocorreas"
                        ? "navbar-social-icon color-icon-NC"
                        : this.props.restaurant.name === "griller"
                        ? "navbar-social-icon color-icon-gri"
                        : this.props.restaurant.selectedTheme === "a"
                        ? "navbar-social-icon color-icon"
                        : this.props.restaurant.selectedTheme === "b"
                        ? "navbar-social-icon color-icon-2"
                        : this.props.restaurant.selectedTheme === "c"
                        ? "navbar-social-icon color-icon-3"
                        : this.props.restaurant.selectedTheme === "d"
                        ? "navbar-social-icon color-icon-4"
                        : "navbar-social-icon color-icon"
                    }
                    icon={faWhatsappSquare}
                  />
                </a>
              )}
            </div>
            {this.props.restaurant.englishSupport &&
              this.props.restaurant.englishSupport === true && (
                <div
                  style={{ padding: "40px 0px 120px 0px" }}
                  className="language-buttons-container"
                >
                  <button
                    className="language-button es-position"
                    style={{
                      zIndex:
                        this.props.subsidiaryLanguage === "es" ? "2" : "1",
                      backgroundColor:
                        this.props.subsidiaryLanguage === "es"
                          ? "#30545E"
                          : "#ffffff",
                    }}
                    onClick={() =>
                      this.props.handleChangeSubsidiaryLanguage("es")
                    }
                  >
                    <img src={EsFlag} alt="es flag" style={{width: "30px", height: "20px", objectFit: "cover"}}/>
                    <p
                      variant="h3"
                      style={{
                        color:
                          this.props.subsidiaryLanguage === "es"
                            ? "#ffffff"
                            : "#555555",
                      }}
                      className="language-font"
                    >
                      {this.props.subsidiaryLanguage === "es"
                        ? "Español"
                        : "Spanish"}
                    </p>
                  </button>
                  <button
                    className="language-button en-position"
                    style={{
                      zIndex:
                        this.props.subsidiaryLanguage === "en" ? "2" : "1",
                      backgroundColor:
                        this.props.subsidiaryLanguage === "en"
                          ? "#30545E"
                          : "#ffffff",
                    }}
                    onClick={() =>
                      this.props.handleChangeSubsidiaryLanguage("en")
                    }
                  >
                    <img src={UkFlag} alt="uk flag" style={{width: "30px", height: "20px", objectFit: "cover"}}/>
                    <p
                      variant="h3"
                      style={{
                        color:
                          this.props.subsidiaryLanguage === "en"
                            ? "#ffffff"
                            : "#555555",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                      className="language-font"
                    >
                      {this.props.subsidiaryLanguage === "es"
                        ? "Inglés"
                        : "English"}
                    </p>
                  </button>
                </div>
              )}
            {this.props.restaurant.name !== "ufopoint" ? (
              <div>
                {/*<img src={division} className="navbar-division" alt="" />*/}
                <div
                  className={
                    this.props.restaurant.name === "griller"
                      ? "navbar-sign-content color-sign-gri"
                      : this.props.restaurant.selectedTheme === "a" ||
                        this.props.restaurant.selectedTheme === "b"
                      ? "navbar-sign-content color-sign"
                      : this.props.restaurant.selectedTheme === "c"
                      ? "navbar-sign-content color-sign-2"
                      : this.props.restaurant.selectedTheme === "d"
                      ? "navbar-sign-content color-sign-3"
                      : "navbar-sign-content color-sign"
                  }
                  style={{ background: "#C8C8C8 !important" }}
                >
                  <div className="navbar-fixed-image">
                    <img src={sign} className="navbar-sign" alt="" />
                  </div>
                </div>
              </div>
            ) : (
              <div className="navbar-lower-section">
                <img
                  src={this.props.restaurant.imageHome}
                  alt=""
                  className="navbar-image"
                />
                {this.props.restaurant.name === "ufopoint" && (
                  <div className="navbar-image-back" />
                )}
              </div>
            )}
          </div>
        </Menu>
      </nav>
    );
  }
}

export default Navbar;
