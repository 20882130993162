import { IconButton } from "@material-ui/core";
import React from "react";
import heartIcon from "../../assets/Menu/heart-icon.svg";
import filledHeartIcon from "../../assets/Menu/filled-heart-icon.svg";

const FavouriteButton = ({
  restaurant,
  product,
  favourite,
  isInFavourites,
  addFavouriteDish,
  deleteFavouriteDish,
}) => {
  return (
    <div
      className={`heart-icon-container`}
      onClick={() => {
        if (favourite.length > 0) {
          const isFound = isInFavourites(product);

          if (isFound) {
            deleteFavouriteDish(product);
          } else {
            addFavouriteDish(product);
          }
        } else {
          addFavouriteDish(product);
        }
      }}
    >
      <IconButton
        aria-haspopup="true"
        className="subcateg-product-list-dish "
        style={
          favourite.includes(product.name)
            ? {
                color: "#CD5C5C",
                padding: "0",
              }
            : restaurant.name === "griller"
            ? {
                color: "#FFFFFF",
                padding: "0",
              }
            : restaurant.selectedTheme === "a" ||
              restaurant.selectedTheme === "b"
            ? {
                color: "#181A1B",
                padding: "0",
              }
            : {
                color: "#000000",
                padding: "0",
              }
        }
        disableRipple={true}
        onClick={() => {
          if (favourite.length > 0) {
            const isFound = isInFavourites(product);
            if (isFound !== undefined) {
              deleteFavouriteDish(product);
            } else {
              addFavouriteDish(product);
            }
          } else {
            addFavouriteDish(product);
          }
        }}
      >
        {isInFavourites(product) ? (
          <img src={filledHeartIcon} className="heartIcon" alt="" />
        ) : (
          <img src={heartIcon} className="heartIcon" alt="" />
        )}
      </IconButton>
    </div>
  );
};

export default FavouriteButton;
