import React from "react";
import { Typography } from "@material-ui/core";

// Assets
import american from "../../assets/RestaurantInfo/american.png";
import visa from "../../assets/RestaurantInfo/visa.png";
import master from "../../assets/RestaurantInfo/master.png";
import mercadoPago from "../../assets/RestaurantInfo/mercado-pago.png";
import cardsIcon from "../../assets/RestaurantInfo/cards-icon.svg";
import cardsIcon2 from "../../assets/RestaurantInfo/cards-icon-2.svg";

// Style
import "./RestaurantInfo.css";

import { useTranslate } from "react-translate";
import { useAppContext } from "../../contexts/AppProvider";
import { makeAbsoluteUrl } from "../../constants";

function RestaurantInfo(props) {
  const { subsidiaryLanguage } = useAppContext();

  let t = useTranslate("RESTAURANT_INFO");

  return (
    <div className="restaurant-info">
      <Typography
        variant="body2"
        color="textSecondary"
        className="restaurant-info-text"
      >
        {subsidiaryLanguage === "es"
          ? props.restaurant?.welcome
          : props.restaurant?.englishWelcome
          ? props.restaurant?.englishWelcome
          : props.restaurant?.welcome}
      </Typography>
      {(props.restaurant.imageHome || props.restaurant.englishHomeImage) && (
        <div className="restaurant-info-cards" style={{ marginTop: "40px" }}>
          {(props.restaurant.imageHome && props.restaurant.urlHomeImage) ||
          (props.restaurant?.englishHomeImage &&
            props.restaurant?.urlEnglishHomeImage) ? (
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={
                subsidiaryLanguage === "es"
                  ? makeAbsoluteUrl(props.restaurant.urlHomeImage)
                  : makeAbsoluteUrl(props.restaurant?.urlEnglishHomeImage)
              }
            >
              {subsidiaryLanguage === "es" && props.restaurant.imageHome && (
                <img
                  src={props.restaurant.imageHome}
                  alt=""
                  className="restaurant-info-img"
                />
              )}
              {subsidiaryLanguage === "en" &&
                props.restaurant?.englishHomeImage && (
                  <img
                    src={props.restaurant.englishHomeImage}
                    alt=""
                    className="restaurant-info-img"
                  />
                )}
            </a>
          ) : (
            <>
              {subsidiaryLanguage === "es" && props.restaurant.imageHome && (
                <img
                  src={props.restaurant.imageHome}
                  alt=""
                  className="restaurant-info-img"
                />
              )}
              {subsidiaryLanguage === "en" &&
                props.restaurant?.englishHomeImage && (
                  <img
                    src={props.restaurant.englishHomeImage}
                    alt=""
                    className="restaurant-info-img"
                  />
                )}
            </>
          )}
        </div>
      )}
      {props.restaurant.selectedAmerican === true ||
      props.restaurant.selectedMaster === true ||
      props.restaurant.selectedVisa === true ? (
        <div className="restaurant-info-cards">
          <img
            className="restaurant-info-cards-icon"
            src={
              props.restaurant.selectedTheme === "a" ||
              props.restaurant.selectedTheme === "b"
                ? cardsIcon
                : props.restaurant.selectedTheme === "c" ||
                  props.restaurant.selectedTheme === "d"
                ? cardsIcon2
                : null
            }
            alt=""
          />
          <Typography
            variant="body2"
            color="textSecondary"
            className="restaurant-info-cards-text"
          >
            {t("CARDS")}
          </Typography>
          <div className="restaurant-info-cardlist">
            {props.restaurant.selectedVisa && (
              <img className="restaurant-info-visa-item" src={visa} alt="" />
            )}
            {props.restaurant.selectedAmerican && (
              <img
                className="restaurant-info-american-item"
                src={american}
                alt=""
              />
            )}
            {props.restaurant.selectedMaster && (
              <img
                className="restaurant-info-master-item"
                src={master}
                alt=""
              />
            )}
            {props.restaurant.selectedMercadoPago && (
              <img
                className="restaurant-info-mercado-pago-item"
                src={mercadoPago}
                alt=""
              />
            )}
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

export default RestaurantInfo;
