import { createTheme } from "@mui/material/styles";

const ufoTheme = createTheme({
  palette: {
    primary: {
      main: "#b79568",
    },
    secondary: {
      main: "#d5d3d3",
    },
    text: {
      primary: "#ffffff",
      secondary: "#d5dbe2",
    },
    background: {
      default: "#181A1B",
      paper: "#181A1B",
    },
  },
  typography: {
    fontFamily: ["Bebas Neue"],
    h1: {
      fontWeight: "bold",
      fontSize: "15px",
      marginTop: "10px",
    },
    h2: {
      fontWeight: "400",
      fontSize: "15px",
      marginTop: "10px",
      fontFamily: ["Montserrat"],
      letterSpacing: "2px",
    },
    h3: {
      fontWeight: "400",
      fontSize: "22px",
    },
    body1: {
      fontSize: "13px",
      margin: "5px 0 3px 0",
      fontFamily: ["Montserrat"],
    },
    h6: {
      fontSize: "9px",
      fontFamily: ["Montserrat"],
      fontWeight: "500",
      letterSpacing: "0.8px",
      textTransform: "capitalize",
    },
    h5: {
      fontSize: "13px",
      fontWeightL: "500"
    },
  },
});

export default ufoTheme;