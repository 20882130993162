import React, { useEffect } from "react";

// Assets
import crossIcon from "../../assets/Suggested/cross-icon.svg";
import sugIcon from "../../assets/Suggested/sug-icon.svg";
import sugWhiteIcon from "../../assets/Suggested/sug-icon-white.svg";
import arrow from "../../assets/Suggested/arrow.svg";

// Style
import "./SuggestedAlert.css";

import { useTranslate } from "react-translate";

function SuggestedAlert(props) {

  let t = useTranslate("SUGGESTED");

  useEffect(() => {
    document.body.style.overflow = "hidden";
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="suggested-alert-container">
      <div className="suggested-alert" onClick={props.disableSuggestedAlert}>
        <img
          src={crossIcon}
          className="suggested-alert-cross"
          onClick={props.disableSuggestedAlert}
          alt=""
        />
        <img
          src={
              props.restaurant.name === "griller"
              ? sugWhiteIcon : sugIcon
          }
          className="suggested-alert-sug animate__animated animate__tada"
          onClick={() => {
            props.disableSuggestedAlert();
          }}
          alt=""
        />
        <img src={arrow} className="suggested-alert-arrow" alt="" />
        <p className="suggested-alert-text">
          {t("MESSAGE_TOP")}<br />{t("MESSAGE_BOTTOM")}
        </p>
      </div>
    </div>
  );
}

export default SuggestedAlert;
