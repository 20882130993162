import React from "react";

// Style
import "./Auspice.css";

function Auspice(props) {
  return (
    <div className="auspice-container">
      {props.auspice.map(
        (auspice, index) =>
          auspice.url && (
            <img className="auspice-img" key={index} src={auspice.url} alt="" />
          )
      )}
    </div>
  );
}

export default Auspice;
