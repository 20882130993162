import { createTheme } from "@mui/material/styles";

const defaultTheme = createTheme({
  palette: {
    primary: {
      main: "#a24f1d",
    },
    secondary: {
      main: "#575756",
    },
    text: {
      primary: "#808080",
      secondary: "#002e3c",
    },
    background: {
      default: "#fff",
    },
  },
  typography: {
    fontFamily: ["Montserrat"],
    h1: {
      fontWeight: "bold",
      fontSize: "15px",
      marginTop: "10px",
    },
    h2: {
      fontWeight: "400",
      fontSize: "15px",
      marginTop: "10px",
    },
    h3: {
      fontWeight: "400",
      fontSize: "15px",
      marginTop: "10px",
    },
    h4: {
      fontWeight: "400",
      fontSize: "13px",
      letterSpacing: "2px",
      marginTop: "10px",
    },
    body1: {
      fontSize: "13px",
      margin: "1em 0",
    },
    body2: {
      fontFamily: ["Roboto Slab"],
      fontSize: "13px",
      margin: "13px 0 13px 0",
    },
    h5: {
      fontSize: "13px",
      fontWeightL: "500"
    },
    h6: {
      fontSize: "9px",
    },
  },
});

const alternativeTheme = createTheme({
  palette: {
    primary: {
      main: "#FB5E5E",
    },
    secondary: {
      main: "#757474",
    },
    text: {
      primary: "#999898",
      secondary: "#A32727",
    },
    background: {
      default: "#fff",
    },
  },
  typography: {
    fontFamily: ["Montserrat"],
    h1: {
      fontWeight: "bold",
      fontSize: "15px",
      marginTop: "10px",
    },
    h2: {
      fontWeight: "400",
      fontSize: "15px",
      marginTop: "10px",
    },
    h3: {
      fontWeight: "400",
      fontSize: "15px",
      marginTop: "10px",
    },
    body1: {
      fontSize: "13px",
      margin: "1em 0",
    },
    body2: {
      fontFamily: ["Roboto Slab"],
      fontSize: "13px",
      margin: "13px 50px 13px 50px",
    },
    h5: {
      fontSize: "13px",
      fontWeightL: "500"
    },
    h6: {
      fontSize: "9px",
    },
  },
});

const summerTheme = createTheme({
  palette: {
    primary: {
      main: "#8E5026",
    },
    secondary: {
      main: "#8C887B",
    },
    text: {
      primary: "#E4E4E3",
      secondary: "#948D79",
    },
    background: {
      default: "#1C323E",
      paper: "#1C323E",
    },
  },
  typography: {
    fontFamily: ["Montserrat"],
    h1: {
      fontWeight: "bold",
      fontSize: "15px",
      marginTop: "10px",
    },
    h2: {
      fontWeight: "400",
      fontSize: "15px",
      marginTop: "10px",
    },
    h3: {
      fontWeight: "400",
      fontSize: "15px",
      marginTop: "10px",
    },
    body1: {
      fontSize: "13px",
      margin: "1em 0",
    },
    body2: {
      fontFamily: ["Roboto Slab"],
      fontSize: "13px",
      margin: "13px 50px 13px 50px",
    },
    h5: {
      fontSize: "13px",
      fontWeightL: "500"
    },
    h6: {
      fontSize: "9px",
    },
  },
});

const darkTheme = createTheme({
  palette: {
    primary: {
      main: "#8E5026",
    },
    secondary: {
      main: "#8C887B",
    },
    text: {
      primary: "#E4E4E3",
      secondary: "#968E7A",
    },
    background: {
      default: "#181A1B",
      paper: "#181A1B",
    },
  },
  typography: {
    fontFamily: ["Montserrat"],
    h1: {
      fontWeight: "bold",
      fontSize: "15px",
      marginTop: "10px",
    },
    h2: {
      fontWeight: "400",
      fontSize: "15px",
      marginTop: "10px",
    },
    h3: {
      fontWeight: "400",
      fontSize: "15px",
      marginTop: "10px",
    },
    body1: {
      fontSize: "13px",
      margin: "1em 0",
    },
    body2: {
      fontFamily: ["Roboto Slab"],
      fontSize: "13px",
      margin: "13px 50px 13px 50px",
    },
    h5: {
      fontSize: "13px",
      fontWeightL: "500"
    },
    h6: {
      fontSize: "9px",
    },
  },
});

export { defaultTheme, alternativeTheme, summerTheme, darkTheme };