import React, { useEffect, useState } from "react";
import { Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import { useFirestoreConnect, isLoaded } from "react-redux-firebase";
import CircularProgress from "@material-ui/core/CircularProgress";

// Google Tag Manager
import ReactGa from "react-ga";

// Pages
import Landing from "./pages/Landing/Landing";
import Main from "./pages/Main/Main";

// General styles
import "./normalize.css";
import "./style.css";

// Assets
import logo from "./assets/Background/logo.svg";
import mimenu from "./assets/Background/mimenu.svg";

//translate

function App(props) {
  const setHeight = () => {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  };

  const setWidth = () => {
    const vw = window.innerWidth * 0.01;
    document.documentElement.style.setProperty("--vw", `${vw}px`);
  };

  // Firebase
  const array = props.location.pathname.split("/");
  const id = array[1];

  // useFirestoreConnect([`restaurants`]);
  useFirestoreConnect({
    collection: "restaurants",
    where: ["name", "==", id],
  });

  /**
   * Se recorre cada document de la collection {restaurants}, y primero se chequea si el field {name} del document
   * coincide con el {id} -sacado de la url-. Si coincide se devuelven esos datos. Si no coincide se hace el chequeo
   * de si coincide el {id} con la key del document. Si coincide se devuelven esos datos. Caso contrario se continúa
   * recorriendo.
   */

  //  const restaurantData = useSelector(({ firestore: { data } }) => {
  //   for (const collectionKey in data) {
  //     for (const documentKey in data[collectionKey]) {
  //       if (data[collectionKey][documentKey].name === id) {
  //         return data[collectionKey][documentKey];
  //       } else if (documentKey === id) {
  //         return data[collectionKey][documentKey];
  //       }
  //     }
  //   }
  // });
  const restaurantData = useSelector(
    ({
      firestore: {
        data: { restaurants },
      },
    }) => {
      if (restaurants) return Object.values(restaurants)[0];
    }
  );

  useEffect(() => {
    setHeight();
    window.addEventListener("resize", setHeight);
    setWidth();
    window.addEventListener("resize", setWidth);
    ReactGa.initialize("UA-181602660-2");
    ReactGa.pageview(window.location.pathname + window.location.search);
  }, []);

  const [language, setLanguage] = useState("es");

  return id ? (
    isLoaded(restaurantData) ? (
      restaurantData.active ? (
        <React.Fragment>
          <Route
            exact
            path="/:id/menu"
            render={(props) => (
              <Main
                {...props}
                restaurant={restaurantData}
                id={id}
                language={language}
                setLanguage={setLanguage}
              />
            )}
          />
          <Route
            exact
            path="/:id"
            render={(props) => (
              <Landing
                {...props}
                restaurant={restaurantData}
                id={id}
                language={language}
                setLanguage={setLanguage}
              />
            )}
          />
          <Redirect exact from="/:id/" to={"/" + id} />
        </React.Fragment>
      ) : restaurantData.active === false ? (
        <div className="main">
          <img src={logo} className="main-logo" alt="" />
          <img src={mimenu} className="main-logo-text" alt="" />
        </div>
      ) : (
        <React.Fragment>
          <Route
            exact
            path="/:id/menu"
            render={(props) => (
              <Main
                {...props}
                restaurant={restaurantData}
                id={id}
                language={language}
                setLanguaje={setLanguage}
              />
            )}
          />
          <Route
            exact
            path="/:id"
            render={(props) => (
              <Landing
                {...props}
                restaurant={restaurantData}
                id={id}
                language={language}
                setLanguaje={setLanguage}
              />
            )}
          />
          <Redirect exact from="/:id/" to={"/" + id} />
        </React.Fragment>
      )
    ) : (
      <div className="circular-progress">
        <CircularProgress />
      </div>
    )
  ) : (
    <div className="main">
      <img src={logo} className="main-logo" alt="" />
      <img src={mimenu} className="main-logo-text" alt="" />
    </div>
  );
}

export default App;
